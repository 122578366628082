/* global i18n */

export const getMonthName = (monthIndex) => {
  const months = [
    'month_january',
    'month_february',
    'month_march',
    'month_april',
    'month_may',
    'month_june',
    'month_july',
    'month_august',
    'month_september',
    'month_october',
    'month_november',
    'month_december'
  ]

  const key = months[monthIndex]

  return key ? i18n.t(key) : null
}

export const getYear = (timestamp) => {
  const date = new Date(timestamp * 1000)
  return date.getFullYear()
}

export const getMonth = (timestamp) => {
  const date = new Date(timestamp * 1000)
  return date.getMonth()
}

export const getDay = (timestamp) => {
  const date = new Date(timestamp * 1000)
  return date.getDate()
}
