import React from 'react'
import cn from 'classnames'

const StatBox = ({ icon, text }) => {
  return (
    <div style={{ position: 'relative', cursor: 'default' }} className="tag">
      <span style={{ position: 'relative' }}>
        <div className="loaderbtn" style={{ position: 'absolute', visibility: 'hidden' }} />
        <i className={cn('speciali', icon)} />
        <span>{text}</span>
      </span>
    </div>
  )
}

export default StatBox
