/* global i18n */

import React from 'react'
import { When } from 'react-if'
import { baseUrl } from '../../../../common/constants.js'
import EllipsisButton from '../../../../components/EllipsisButton/EllipsisButton.jsx'
import DateTime from '../../../../components/DateTime/DateTime.jsx'
import { xhrPost } from '../../../../helpers/http.js'
import s from './style.module.scss'

const Comment = ({ user, modified, comment, canDelete, id, onDelete }) => {
  const isLoggedIn = window.__initial_data__.user.customerId !== null

  const { customerId } = window.__blog

  const blockUser = async (userId) => {
    await xhrPost(`${baseUrl}/web/user/block`, {
      userId
    })
    console.log('blokkolva; TODO: frissíteni a listát')
    document.location.reload(true)
  }

  const unblockUser = async (userId) => {
    await xhrPost(`${baseUrl}/web/user/unblock`, {
      userId
    })
    console.log('unblokkolva; TODO: frissíteni a listát')
    document.location.reload(true)
  }

  return (
    <div className={s.Comment}>
      <div className={s.avatar}>
        <a href={`${baseUrl}/blog/${user.blogname}`}>
          <img src={user.avatarimage} />
        </a>
      </div>
      <div className={s.blogname}>
        <strong>{user.blogname}</strong>
        <small>ID {user.id}</small>
      </div>
      <div className={s.date}>
        <DateTime input={modified} inputFormat="atom" />
      </div>
      <div className={s.comment}>{comment}</div>
      <div className={s.options}>
        <When condition={isLoggedIn}>
          {() => {
            const items = []
            if (customerId !== parseInt(user.id)) {
              if (user.isBlockedByUs) {
                items.push({
                  label: i18n.t('ellipsis_unblock_user'),
                  onClick: () => {
                    unblockUser(user.id)
                  }
                })
              } else {
                items.push({
                  label: i18n.t('ellipsis_block_user'),
                  onClick: () => {
                    blockUser(user.id)
                  }
                })
              }
            }
            if (canDelete) {
              items.push({
                label: i18n.t('ellipsis_delete_comment'),
                onClick: () => {
                  onDelete(id)
                }
              })
            }
            return <EllipsisButton items={items} />
          }}
        </When>
      </div>
    </div>
  )
}

export default Comment
