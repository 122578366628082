/* global R, RA */

const baseUrl = window.location.origin
const currency = 'USD'

// ne töröld ki a nem használtakat, mert a többi fájl használja
const {
  values,
  sum,
  toPairs,
  forEach,
  map,
  join,
  reverse,
  sortBy,
  nth,
  compose,
  isNil,
  isEmpty,
  propOr,
  trim,
  last,
  toString,
  has,
  range,
  split,
  filter,
  assoc,
  reject,
  anyPass,
  equals,
  reduce,
  pluck,
  prop,
  assocPath,
  replace,
  none,
  propEq,
  find,
  memoizeWith,
  mergeDeepRight,
  defaultTo,
  invert,
  head
} = R

const { isPlainObj, isNotEmpty } = RA

// TODO: ezt törölni, ha már minden import-tal van behúzva
const isBetween = (min, max, number) => {
  return number >= min && number < max
}

// http://photogram/weboffice/api/users?limit=10&offset=0&exclude[]=2134617&exclude[]=12&exclude[]=10
const toURI = (obj) => {
  return Object.entries(obj)
    .filter(([key, value]) => {
      return !isNil(value)
    })
    .reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        return [
          ...acc,
          ...value.map((val) => {
            return `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`
          })
        ]
      } else if (isPlainObj(value)) {
        // TODO: ez ne stringify-olt JSON-nal működjön
        return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`]
      } else {
        return [...acc, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`]
      }
    }, [])
    .join('&')
}

const parseURI = (uri) => {
  return compose(
    reduce((acc, [key, value]) => {
      return assoc(decodeURIComponent(key), decodeURIComponent(value), acc)
    }, {}),
    map(split('=')),
    filter(isNotEmpty),
    split('&')
  )(uri)
}

const getQueryParams = () => {
  return parseURI(window.location.search.replace(/^\?/, ''))
}

const isString = (x) => {
  return typeof x === 'string'
}

const prefixIfNotEmpty = (prefix, str) => {
  if (!isString(str) || isEmpty(str)) {
    return ''
  } else {
    return prefix + str
  }
}

const xhrGet = async (url, params = {}) => {
  const query = prefixIfNotEmpty('?', toURI(params))
  const response = await fetch(`${url}${query}`)
  if (!isBetween(200, 300, response.status)) {
    throw new Error(`Failed to GET "${url}${query}"`)
  }
  return response.json()
}

const xhrDelete = async (url) => {
  const response = await fetch(url, {
    method: 'delete'
  })
  if (!isBetween(200, 300, response.status)) {
    throw new Error(`Failed to DELETE "${url}"`)
  }
  return response.json()
}

const xhrPost = async (url, data = {}, isFormData = false, isDownload = false) => {
  const response = await fetch(url, {
    method: 'post',
    headers: isFormData
      ? undefined
      : {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
    body: isFormData ? data : toURI(data)
  })
  if (!isBetween(200, 300, response.status)) {
    throw response
  }
  if (!isDownload) {
    return response.json()
  } else {
    response.blob().then((blob) => {
      const file = window.URL.createObjectURL(blob)
      downloadURI(file, '')
    })
  }
}

const xhrPatch = async (url, data = {}) => {
  const response = await fetch(url, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: toURI(data)
  })
  if (!isBetween(200, 300, response.status)) {
    throw response
  }
  return response.json()
}

const xhrPut = async (url, data = {}) => {
  const response = await fetch(url, {
    method: 'put',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: toURI(data)
  })
  if (!isBetween(200, 300, response.status)) {
    throw response
  }
  return response.json()
}

const removeElement = (element) => {
  element.parentNode.removeChild(element)
}

const clearChildNodes = (element) => {
  while (element.firstChild) {
    element.removeChild(element.lastChild)
  }
  return element
}

const generateUniqId = () => {
  return `${Date.now()}-${Math.floor(Math.random() * 1e6)}`
}

// szegény ember classnames modulja, csak primitív értékekkel tud dolgozni
const classnames = (...args) => {
  return compose(join(' '), map(trim), reject(anyPass([isNil, equals(false), isEmpty])))(args)
}

// "alma[sajt][bab]" -> ["alma", sajt", "bab"]
const formKeyToPath = (key) => {
  return key.split(/\]?\[/g).map(replace(']', ''))
}

const formToJSON = (form) => {
  let obj = {}

  for (const [key, value] of new FormData(form).entries()) {
    obj = assocPath(formKeyToPath(key), value, obj)
  }

  return obj
}

const formatNumber = (num, thousandsSeparator = ' ') => {
  // forrás: https://stackoverflow.com/a/2901298/1806628
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
}

const formatMoney = (num) => {
  return `${formatNumber(num)} ${currency}`
}

function downloadURI(uri, name) {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
