import React from 'react'
import { baseUrl } from '../../common/constants.js'
import SocialShare from '../../components/SocialShare/SocialShare.jsx'

const ReferralLinkShare = () => {
  const sponsorId = window.__initial_data__.user.customerId
  const url = `${baseUrl}/short-registration?sponsorId=${sponsorId}`

  return <SocialShare url={url} copiedLabel="sponsor_link_copied" clickToCopyLabel="sponsor_link_click_to_copy" />
}

export default ReferralLinkShare
