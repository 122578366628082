/* eslint-disable linebreak-style */
/* eslint-disable prettier/prettier */
/* global i18n */
/* global $ */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { flatten, values, any, path, equals, compose, either } from 'ramda'
import Button from '../../../../components/Button/Button.jsx'
import { xhrGet, xhrPost } from '../../../../helpers/http.js'
import { callTronlink, isTronLinkReady } from '../../../../helpers/tronlink.js'
import { baseUrl } from '../../../../common/constants.js'

const PayWithTronLink = () => {
  const apiStartPayment = async () => {
    return xhrPost(`${baseUrl}/web/cart/payment/start`, {
      method: 'tronlink'
    })
  }

  const apiAbortPayment = async () => {
    return xhrPost(`${baseUrl}/web/cart/payment/abort`)
  }

  const activatorPhotoNeed = 10

  const dispatch = useDispatch()

  const items = useSelector((state) => state.Cart.items)
  const isLoading = useSelector((state) => state.Cart.isLoading)
  const settings = useSelector((state) => state.Cart.settings)
  const services = useSelector((state) => state.Activation.services)

  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Cart.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const setItems = (items) => {
    dispatch({
      type: 'Cart.setItems',
      payload: {
        items
      }
    })
  }

  const payReady = async (info) => {
    console.log(info.tx)
    console.log(info)

    const response = await xhrPost(`${baseUrl}/web/cart/savetrontx`, {
      info: info
    })

    if (response.success === true) {
      dispatch({
        type: 'Cart.setIsUnderPayment',
        payload: {
          isUnderPayment: true
        }
      })
    }
  }

  const abortPayment = async () => {
    $('#cartnum').show()
    setIsLoading(true)
    setItems(await apiAbortPayment())
    setIsLoading(false)
  }

  const showError = (error) => {
    if (typeof error === 'string') {
      alert(error)
    } else if (typeof error.message === 'string') {
      alert(error.message)
    } else {
      alert(JSON.stringify(error.message))
    }
  }

  const payTronLink = async () => {
    xhrGet(`${baseUrl}/web/cart/getprices`)
      .then(async (prices) => {
        $('#cartnum').hide()

        const activatorimageprice = prices.activatorphotosprice
        const bigimageprice = prices.bigphotoprice
        const smallimageprice = prices.smallphotoprice
        const commission = prices.commission

        if (items.photosForService.length) {
          console.log(items.photosForService)
          console.log(services)
          const offset=new Date().getTimezoneOffset()*60*1000;
          const timeNow=new Date().getTime()+offset;
          let sdate=new Date(items.photosForService[0].service.validFrom).getTime();

          if (sdate<timeNow) {sdate=timeNow+(3600000*24);}

          sdate=parseInt(sdate/1000);

          const troninfo = {
            startdate: sdate,
            users: []
          }
          items.photosForService.forEach(function (photo) {
            troninfo.users.push(photo.usertronaddress)
          })
          const sponsoraddress = items.photosForService[0].sponsoraddress

          const price = activatorPhotoNeed * activatorimageprice
          callTronlink(
            'activeService(uint256,address[],address,uint256,uint256)',
            price,
            settings.contractAddress,
            settings.walletAddress,
            [
              { type: 'uint256', value: troninfo.startdate },
              { type: 'address[]', value: troninfo.users },
              { type: 'address', value: sponsoraddress },
              { type: 'uint256', value: commission * activatorPhotoNeed },
              { type: 'uint256', value: activatorimageprice - commission }
            ]
          )
            .then((info) => {
              payReady(info)
            })
            .catch((error) => {
              showError(error)
              abortPayment()
            })
        }

        if (items.photosForSector.length) {
          const troninfo = {
            users: []
          }
          items.photosForSector.forEach(function (photo) {
            troninfo.users.push(photo.usertronaddress)
          })

          callTronlink(
            'buySector(address[],uint256,uint256,uint256)',
            activatorPhotoNeed * Math.pow(2, parseInt(items.photosForSector[0].sector) - 1) * activatorimageprice,
            settings.contractAddress,
            settings.walletAddress,
            [
              { type: 'address[]', value: troninfo.users },
              { type: 'uint256', value: items.photosForSector[0].sector },
              {
                type: 'uint256',
                value: commission * activatorPhotoNeed * Math.pow(2, parseInt(items.photosForSector[0].sector) - 1)
              },
              { type: 'uint256', value: activatorimageprice - commission }
            ]
          )
            .then((info) => {
              payReady(info)
            })
            .catch((error) => {
              showError(error)
              abortPayment()
            })
        }

        if (items.photos.length) {
          items.photos.forEach(function (photo) {
            callTronlink(
              'buyPhoto(address,uint256,bool,uint256,uint256)',
              photo.bigphoto ? bigimageprice : smallimageprice,
              settings.contractAddress,
              settings.walletAddress,
              [
                { type: 'address', value: photo.usertronaddress },
                { type: 'uint256', value: photo.photoid },
                { type: 'bool', value: photo.bigphoto },
                { type: 'uint256', value: commission },
                { type: 'uint256', value: photo.bigphoto ? bigimageprice - commission : smallimageprice - commission }
              ]
            )
              .then((info) => {
                payReady(info)
              })
              .catch((error) => {
                showError(error)
                abortPayment()
              })
          })
        }
      })
      .catch((error) => {
        if (typeof error === 'string') {
          alert(error)
        } else {
          alert(JSON.stringify(error))
        }
        abortPayment()
      })
  }

  const startPayment = async () => {
    setIsLoading(true)

    let canPayWithTronLink = false
    if (window.tronWeb !== undefined) {
      if (isTronLinkReady(settings.walletAddress)) {
        canPayWithTronLink = true
      } else {
        alert('Select the correct wallet on the TronLink browser extension')
      }
    } else {
      alert('Please login to TronLink extension wallet first')
    }

    try {
      if (canPayWithTronLink) {
        setItems(await apiStartPayment())
        payTronLink()
      }
    } catch (e) {
      console.error('PayWithWallet: startPayment failed', await e.json())
    }

    setIsLoading(false)
  }

  const flatItemList = useSelector((state) => flatten(values(state.Cart.items)))

  const hasPayableItems = any(
    compose(either(equals('in cart'), equals('payment failed')), path(['commerceinfo', 'statusText']))
  )(flatItemList)

  return (
    <Button nowrap disabled={isLoading || !hasPayableItems} onClick={startPayment} color="red">
      {i18n.t('cart_pay_with_tronlink_wallet')}
    </Button>
  )
}

export default PayWithTronLink
