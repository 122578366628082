/* global i18n */

import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { When } from 'react-if'
import { clamp } from 'ramda'
import { baseUrl } from '../../../../common/constants.js'
import Button from '../../../../components/Button/Button.jsx'
import { xhrDelete, xhrGet } from '../../../../helpers/http.js'
import Comment from '../Comment/Comment.jsx'
import s from './style.module.scss'

const MINIMUM_COMMENTS = 2

const Comments = (props, ref) => {
  const [numberOfCommentsLoaded, setNumberOfCommentsLoaded] = useState(2)
  const [comments, setComments] = useState(props.comments)

  useImperativeHandle(ref, () => ({
    adjustNumberOfComments: async (increase) => {
      const limit = clamp(MINIMUM_COMMENTS, Infinity, numberOfCommentsLoaded + increase)

      const apiURL = `${baseUrl}/web/blog/${props.blogPostId}/comments`
      const comments = await xhrGet(apiURL, {
        limit,
        offset: 0
      })

      setComments(comments)
      setNumberOfCommentsLoaded(comments.length)
    }
  }))

  const minimizeComments = () => {
    const newComments = comments.slice(0, MINIMUM_COMMENTS)
    setComments(newComments)
    setNumberOfCommentsLoaded(newComments.length)
  }

  const loadMoreComments = async () => {
    ref.current.adjustNumberOfComments(3)
  }

  const deleteComment = async (commentId) => {
    const apiURL = `${baseUrl}/web/blog/comment/${commentId}`
    await xhrDelete(apiURL)
    ref.current.adjustNumberOfComments(-1)
    props.onNumberOfCommentsChange(-1)
  }

  return (
    <>
      <div className={s.Comments}>
        {comments.map((comment, index) => {
          return <Comment key={index} {...comment} onDelete={deleteComment} />
        })}
      </div>

      <div className="d-flex justify-content-between buttons">
        {props.allComments > comments.length && (
          <Button onClick={loadMoreComments}>{i18n.t('blog_load_more_comments')}</Button>
        )}
        <When condition={comments.length > 2}>
          {() => (
            <Button onClick={minimizeComments} style={{ marginTop: 0 }}>
              {i18n.t('blog_close_comments')}
            </Button>
          )}
        </When>
      </div>
    </>
  )
}

export default forwardRef(Comments)
