/* global i18n */

import React from 'react'
import { If, Then, Else } from 'react-if'
import { PhotoStatusCodes } from '../../../../common/constants.js'
import Button from '../../../../components/Button/Button.jsx'
import Tooltip from '../../../../components/Tooltip/Tooltip.jsx'
import StatBox from '../StatBox/StatBox.jsx'
import Preview from '../Preview/Preview.jsx'
import StatusIndicator from '../StatusIndicator/StatusIndicator.jsx'
import ToggleVisibility from '../ToggleVisibility/ToggleVisibility.jsx'
import s from './style.module.scss'

const Photo = ({ onDisable, onEnable, onDelete, ...photo }) => {
  return (
    <div className={photo.status === PhotoStatusCodes.pending ? s.Photo + ' ' + s.PhotoPending : s.Photo}>
      <StatusIndicator status={photo.status} approved={photo.company_approved} />

      <div className={s.buttons}>
        <If condition={photo.status === PhotoStatusCodes.approved || photo.status === PhotoStatusCodes.featured}>
          <Then>
            <ToggleVisibility
              isVisible={!photo.disabled_by_user}
              onEnable={onEnable}
              onDisable={onDisable}
              photoId={photo.photoid}
            />
          </Then>
          <Else>&nbsp;</Else>
        </If>

        <Tooltip label={i18n.t('delete_photo_desc')}>
          <Button icon onClick={() => onDelete(photo.photoid)} color="red">
            <i className="fas fa-trash" />
          </Button>
        </Tooltip>
      </div>

      <Preview
        photoId={photo.photoid}
        status={photo.status}
        disabledByUser={photo.disabled_by_user}
        fileName={photo.filename}
        banText={photo.bantext}
      />

      <div>
        <div className="d-flex justify-content-around">
          <StatBox icon="fas fa-thumbs-up" text={photo.cntlike} />
          <StatBox icon="fas fa-star" text={photo.cntfavorite} />
        </div>
        <div className="d-flex justify-content-around">
          <div style={{ position: 'relative', cursor: 'default' }} className="tag">
            <span style={{ position: 'relative' }}>
              <div className="loaderbtn" style={{ position: 'absolute', visibility: 'hidden' }} />
              <i className="fas fa-download speciali" />
              <span>{photo.cntbuy}</span>
            </span>
          </div>
          <StatBox icon="fas fa-thumbs-down" text={photo.cntdislike ?? 0} />
        </div>
      </div>
    </div>
  )
}

export default Photo
