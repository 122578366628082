/* global i18n, isStorageAvailable, restoreData, persistData, notify */

import React, { useState } from 'react'
import { hot, setConfig } from 'react-hot-loader'
import { compose, defaultTo, isEmpty } from 'ramda'
import { If, Then, Else } from 'react-if'
import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from '../../helpers/react.js'
import { xhrGet, xhrPatch } from '../../helpers/http.js'
import Button from '../../components/Button/Button.jsx'
import LoadingText from '../../components/LoadingText/LoadingText.jsx'
import { baseUrl } from '../../common/constants.js'
import BlogItem from './components/BlogItem/BlogItem.jsx'

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const BlogPosts = () => {
  const { target, isSelf, blogPostId } = window.__blog
  const authorId = window.__eventTargetId

  const [page, setPage] = useState(0)
  const [showMore, setShowMore] = useState(true)

  const apiGetPosts = async () => {
    const offset = page * 10
    const limit = 10
    if (target === 'self') {
      return xhrGet(`${baseUrl}/web/blog/my-posts?eventTargetId=${authorId}&offset=${offset}&limit=${limit}`)
    } else {
      return xhrGet(`${baseUrl}/web/blog/timeline?&offset=${offset}`)
    }
  }

  const apiGetPost = async () => {
    return xhrGet(`${baseUrl}/web/blog/${blogPostId}`)
  }

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.BlogPosts.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'BlogPosts.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const posts = useSelector((state) => state.BlogPosts.posts)
  const setPosts = (posts) => {
    dispatch({
      type: 'BlogPosts.setPosts',
      payload: {
        posts
      }
    })
  }

  const loadPhotos = async () => {
    setIsLoading(true)
    try {
      const result = blogPostId === null ? await apiGetPosts() : [await apiGetPost()]
      setPosts([...posts, ...(result.data ?? result)])
      setShowMore(blogPostId !== null || (result.total ? result.total / 10 > page + 1 : true))
    } catch (e) {
      console.error('BlogPosts: loadPhotos failed', await e.json())
    }
    setIsLoading(false)
  }

  const toggleBlogPostComments = async (blogID, state = 1) => {
    setIsLoading(true)
    try {
      await xhrPatch(`${baseUrl}/web/blog/${state ? 'enable' : 'disable'}-comments/${blogID}`)
      if (isStorageAvailable('localStorage')) {
        const notifications = restoreData('notifications', [], defaultTo)
        persistData('notifications', [
          ...notifications,
          {
            title: i18n.t('popup_title_blog_post_comments'),
            subtitle: i18n.t(`popup_blog_post_comments_${state ? 'enabled' : 'disabled'}`),
            severity: 'success'
          }
        ])
      }
      const copyOfPosts = [...posts]
      copyOfPosts.find((el) => el.event.id === blogID).commentsEnabled = Boolean(state)
      setPosts(copyOfPosts)
    } catch (e) {
      setIsLoading(false)
      console.error(e)
      notify(i18n.t('popup_title_blog_post_comments'), i18n.t('popup_blog_post_comments_failed'), 'error')
    }
  }

  const translateBlogPosts = async (blogID, translated = false) => {
    setIsLoading(true)
    try {
      const result = await xhrGet(`${baseUrl}/web/blog/${blogID}?translate=${translated}`)
      const copyOfPosts = [...posts]
      copyOfPosts.find((el) => el.event.id === blogID).photo.description = result.photo.description
      setPosts(copyOfPosts)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }

  useEffectOnce(() => {
    loadPhotos()
    setPage(page + 1)
  })

  /*
  const loadMoreBtn = document.getElementById('load-more-blogitem')
  if (loadMoreBtn) {
    loadMoreBtn.setAttribute('data-blogitem-offset', initialBlogItems.length)
    loadMoreBtn.addEventListener('click', async () => {
      const offset = parseInt(loadMoreBtn.getAttribute('data-blogitem-offset'))

      const apiURL = `${baseUrl}/web/blog/${
        window.location.pathname === '/blog/my-posts' ? 'my-posts' : 'timeline'
      }`
      const events = await xhrGet(apiURL, {
        eventTargetId: window.__eventTargetId,
        offset
      })
      events.forEach((event) => {
        const blogItem = new BlogItem()
        blogItem.on('liked-status-change', updateLikeDataInPosts)
        const element = blogItem.render(event)
        const anchor = loadMoreBtn.parentNode
        anchor.parentNode.insertBefore(element, anchor)
      })

      loadMoreBtn.setAttribute('data-blogitem-offset', offset + events.length)

      // TODO2: scroll-ozzon vissza az előző helyre, hogy a gomb menjen lejjebb és újra meg kelljen keresni
    })
  }
  */

  const loadMoreBlogPosts = () => {
    // id="load-more-blogitem"
    if (blogPostId) {
      window.location.href = window.location.href.replace(blogPostId, '')
    } else {
      setPage(page + 1)
      setIsLoading(true)
      loadPhotos()
    }
  }

  if (isEmpty(posts)) {
    return (
      <div style={{ textAlign: 'center' }}>
        <If condition={target === 'others'}>
          <Then>
            <If condition={isSelf}>
              <Then>
                {() => (
                  <>
                    {/* akiket követünk, azoknak nincs posztja 1 db sem */}
                    <div style={{ textAlign: 'center', width: '100%' }}>{i18n.t('blog_timeline_empty')}</div>
                    {/*
                    <div>
                      <img style={{ width: '100%', marginTop: 20 }} src={baseUrl + '/assets/img/weblog_banner.jpg'} />
                    </div>
                    */}
                  </>
                )}
              </Then>
              <Else>{/* HIBA: ezt nem kéne tudnunk elérni, ez a másik illető timeline-ja */}</Else>
            </If>
          </Then>
          <Else>
            <If condition={isSelf}>
              <Then>
                {() => (
                  <>
                    {/* nincsenek saját posztjaim */}
                    <div style={{ textAlign: 'center', width: '100%' }}>{i18n.t('blog_myposts_empty')}</div>
                  </>
                )}
              </Then>
              <Else>
                {() => (
                  <>
                    {/* az illetőnek nincsenek posztjai */}
                    <div style={{ textAlign: 'center', width: '100%' }}>{i18n.t('blog_hisposts_empty')}</div>
                  </>
                )}
              </Else>
            </If>
          </Else>
        </If>
      </div>
    )
  }

  return (
    <>
      {posts.map((post, index) => {
        return (
          <BlogItem
            key={index}
            {...post}
            toggleBlogPostComments={toggleBlogPostComments}
            translateBlogPosts={translateBlogPosts}
          />
        )
      })}
      <If condition={showMore}>
        <div className="center">
          <Button onClick={loadMoreBlogPosts}>{i18n.t('blog_load_more')}</Button>
        </div>
      </If>
      <If condition={isLoading}>
        <LoadingText />
      </If>
    </>
  )
}

export default enhance(BlogPosts)
