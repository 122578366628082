import React from 'react'
import { trim, compose, join, reject, equals } from 'ramda'

const Name = ({ firstName, lastName, userName }) => {
  const name = compose(
    join(' '),
    reject(equals(''))
  )([trim(firstName + ' ' + lastName), '(' + userName + ')'])

  return <>{name}</>
}

export default Name
