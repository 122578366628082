/* global i18n, notify, xhrPost */

import React, { useEffect, useState } from 'react'
import { If, Then, Else } from 'react-if'
import html2canvas from 'html2canvas'
import { PhotoStatusCodes, baseUrl } from '../../../../common/constants.js'
import Button from '../../../../components/Button/Button.jsx'
import StatBox from '../StatBox/StatBox.jsx'
import Preview from '../Preview/Preview.jsx'
import StatusIndicator from '../StatusIndicator/StatusIndicator.jsx'
import ToggleVisibility from '../ToggleVisibility/ToggleVisibility.jsx'
import '../../../../../public/assets/js/helpers.js'
import '../../../../../public/assets/js/components/pagination.js'
import '../../../../../public/assets/js/components/modal.js'
import '../../../../../public/assets/js/weboffice/helpers.js'
import '../../../../../public/assets/js/weboffice/index.js'
import '../../../../../public/assets/js/weboffice/activation.js'
import '../../../../../public/assets/js/blog/photos.js'
import '../../../../migrate/js/common.js'

import s from './style.module.scss'

const Photo = ({ onDisable, onEnable, onDelete, changeWeight, ...photo }) => {
  const [weight, setWeight] = useState(photo.weight)

  useEffect(() => {
    setWeight(photo.weight)
  }, [photo.weight])

  useEffect(() => {
    const $tooltipEls = window.$('[data-toggle="tooltip-trash"]')
    if ($tooltipEls.length > 0) {
      $tooltipEls.tooltip()
    }
    const $tooltipPriorityEls = window.$('[data-toggle="tooltip-priority"]')
    if ($tooltipPriorityEls.length > 0) {
      $tooltipPriorityEls.tooltip()
    }
  }, [])

  const onShowReferralLinkModal = (photoid) => {
    const $elem = window.$('#voucher-referral-link')
    const $elemText = window.$('#voucher-referral-link-text')
    const elemUrl = $elem.val()
    const lastPerPos = elemUrl.lastIndexOf('/')
    const urlPartBeginText = elemUrl.substring(0, lastPerPos + 1)
    const userid = window.__initial_data__.user.customerId
    const replacedUrl = urlPartBeginText + photoid + '?sponsorid=' + userid
    $elem.val(replacedUrl)
    $elemText.text(replacedUrl)
  }

  function printCanvas(elId) {
    const contentToPrint = document.getElementById(elId)
    html2canvas(contentToPrint).then((canvas) => {
      const printWindow = window.open('', '_blank')
      printWindow.document.body.appendChild(canvas)
      printWindow.print()
    })
  }

  const downloadVoucher = async (event, id) => {
    try {
      await xhrPost(`${baseUrl}/downloadphoto`, { photoid: id }, false, true)
    } catch (e) {
      notify('Something went wrong', '', 'error')
    }
  }

  return (
    <div className={photo.status === PhotoStatusCodes.pending ? s.Photo + ' ' + s.PhotoPending : s.Photo}>
      <StatusIndicator status={photo.status} approved={photo.company_approved} />

      <div className={s.buttons}>
        <If condition={photo.status === PhotoStatusCodes.approved || photo.status === PhotoStatusCodes.featured}>
          <Then>
            <ToggleVisibility
              isVisible={!photo.disabled_by_user}
              onEnable={onEnable}
              onDisable={onDisable}
              photoId={photo.photoid}
            />
          </Then>
          <Else>&nbsp;</Else>
        </If>

        <Button className="ml-auto" icon onClick={() => downloadVoucher(event, photo.photoid)}>
          <i className="fas fa-download infobutton" />
        </Button>

        <Button className="ml-auto" icon onClick={() => printCanvas('print-' + photo.photoid)}>
          <i className="fas fa-print infobutton" style={{ fontSize: '16px' }} />
        </Button>

        <Button
          icon
          data-toggle="modal"
          data-target="#ReferralLinkModalCreated"
          onClick={() => onShowReferralLinkModal(photo.photoid)}
        >
          <i
            className="fas fa-info-circle infobutton d-none d-sm-none d-md-inline-block"
            style={{ fontSize: '16px' }}
          />
        </Button>

        <Button
          icon
          onClick={() => onDelete(photo.photoid)}
          color="red"
          data-toggle="tooltip-trash"
          data-placement="left"
          title={i18n.t('delete_photo_desc')}
        >
          <i className="fas fa-trash" />
        </Button>
      </div>

      <Preview
        photoId={photo.photoid}
        status={photo.status}
        disabledByUser={photo.disabled_by_user}
        fileName={photo.filename}
        banText={photo.bantext}
      />

      <div style={{ position: 'fixed', top: '0', left: '-9999px' }}>
        <div id={'print-' + photo.photoid}>
          <div
            style={{
              display: 'grid',
              'grid-template-columns': '49% 49%',
              gap: '2%',
              width: '980px',
              height: '1176px'
            }}
          >
            <img style={{ width: '100%', height: 'auto' }} src={photo.original_image} alt="" />
            <img style={{ width: '100%', height: 'auto' }} src={photo.original_image} alt="" />
            <img style={{ width: '100%', height: 'auto' }} src={photo.original_image} alt="" />
            <img style={{ width: '100%', height: 'auto' }} src={photo.original_image} alt="" />
          </div>
        </div>
      </div>

      <div>
        <div className="d-flex justify-content-around">
          <StatBox icon="fas fa-thumbs-up" text={photo.cntlike} />
          <StatBox icon="fas fa-star" text={photo.cntfavorite} />
        </div>
        <div className="d-flex justify-content-around">
          <div style={{ position: 'relative', cursor: 'default' }} className="tag">
            <span style={{ position: 'relative' }}>
              <div className="loaderbtn" style={{ position: 'absolute', visibility: 'hidden' }} />
              <i className="fas fa-download speciali" />
              <span>{photo.cntbuy}</span>
            </span>
          </div>
          <StatBox icon="fas fa-thumbs-down" text={photo.cntdislike ?? 0} />
        </div>
        <div className="d-flex justify-content-around align-items-center">
          <span
            style={{ marginRight: 10 }}
            data-toggle="tooltip-priority"
            data-placement="right"
            data-trigger="hover"
            title={i18n.t('priority_tooltip_text')}
          >
            {i18n.t('priolity')}:
          </span>
          <input
            className="change-priority form-control"
            style={{ minWidth: 42, border: 'solid 1px' }}
            defaultValue={photo.weight}
            value={weight}
            type="number"
            min={0}
            onChange={(e) => setWeight(e.target.value)}
            onBlur={() => changeWeight(photo.photoid, weight, photo.weight)}
          />
        </div>
      </div>
    </div>
  )
}

export default Photo
