/* eslint-disable linebreak-style */
/* eslint-disable prettier/prettier */
/* global i18n */

import React, {useState} from 'react'
import {hot, setConfig} from 'react-hot-loader'
import {compose} from 'ramda'
import {useDispatch, useSelector} from 'react-redux'
// import LoadingText from '../../components/LoadingText/LoadingText.jsx'
import {useEffectOnce} from '../../helpers/react.js'
import Separator from '../../components/Separator/Separator.jsx'
import Title from '../../components/Title/Title.jsx'
import {xhrGet} from '../../helpers/http.js'
import {baseUrl} from '../../common/constants.js'
import StatusIndicator from './components/StatusIndicator/StatusIndicator.jsx'
import ServiceSelector from './components/ServiceSelector/ServiceSelector.jsx'

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const Activation = () => {
  const [isCompanyUser, setIsCompanyUser] = useState(false);

  const apiGetServices = async () => {
    return xhrGet(`${baseUrl}/web/services`)
  }

  const apiGetCurrentService = async () => {
    return xhrGet(`${baseUrl}/web/service/current`)
  }

  const dispatch = useDispatch()

  // const isLoading = useSelector((state) => state.Activation.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Activation.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const services = useSelector((state) => state.Activation.services)
  const setServices = (services) => {
    dispatch({
      type: 'Activation.setServices',
      payload: {
        services
      }
    })
  }

  // const selectedServiceId = useSelector((state) => state.Activation.selectedServiceId)
  const setSelectedServiceId = (selectedServiceId) => {
    dispatch({
      type: 'Activation.setSelectedServiceId',
      payload: {
        selectedServiceId
      }
    })
  }

  const loadServices = async () => {
    setIsLoading(true)
    try {
      const {services, isCompanyUser} = await apiGetServices()
      const {serviceId} = await apiGetCurrentService()
      setServices(services)
      setIsCompanyUser(isCompanyUser)
      setSelectedServiceId(serviceId)
    } catch (e) {
      console.error('Activation: loadServices failed', await e.json())
    }
    setIsLoading(false)
  }

  useEffectOnce(() => {
    loadServices()
  })

  // const selectedServiceIndex = findIndex((service) => service.serviceid === selectedServiceId, services)

  return (
    <>
      <Title align="left" style={{ display: 'inline-block', marginTop: '0.375em' }}>
        {i18n.t('office_activation_header')}
      </Title>
      <ServiceSelector services={services} isCompanyUser={isCompanyUser} />
      {/* {selectedServiceIndex >= 0 && services[selectedServiceIndex].fromid !== null && ( */}
      {/*  <div style={{display: 'inline-block', marginLeft: 20}}> */}
      {/*    <span style={{fontWeight: 900}}>{i18n.t('office_activation_gift')}{' '}ID {services[selectedServiceIndex].fromid}</span> */}
      {/*  </div> */}
      {/* )} */}
      <div
        style={{
          backgroundColor: 'transparent',
          padding: '6 15'
        }}
      >
        {i18n
          .t('activation_step')
          .split('<br>')
          .map((str, key) => (
            <p key={key}>{str}</p>
          ))}
      </div>

      <Separator horizontal />
      <StatusIndicator />
      {/* <Separator horizontal /> */}
      {/* ThreeUsers, SevenUsers, AddToCart */}
      {/* <Separator horizontal /> */}
      {/*  képek feltöltése */}
      {/* <LoadingText visible={isLoading} /> */}
    </>
  )
}

export default enhance(Activation)
