/* global baseUrl */

const filenameToPhotoPath = (filename) => {
  return `${baseUrl}/photos/${filename}`
}

const filenameToBlogPhotoPath = (filename) => {
  return `${baseUrl}/blog_images/${filename}`
}

const userIdToUserPath = (userid) => {
  return `${baseUrl}/user/${userid}`
}

const cssUrlToPath = (rule) => {
  return rule.replace(/^url\("/, '').replace(/"\)$/, '')
}

window.filenameToPhotoPath = filenameToPhotoPath
window.filenameToBlogPhotoPath = filenameToBlogPhotoPath
window.userIdToUserPath = userIdToUserPath
window.cssUrlToPath = cssUrlToPath
