import React from 'react'
import cn from 'classnames'
import { When } from 'react-if'
import { range } from 'ramda'
import Button from '../Button/Button.jsx'
import s from './style.module.scss'

const Pagination = ({ onChange, limit, offset, count, variant = null, buttonLimit = 3 }) => {
  if (count <= limit) {
    return null
  }

  const numberOfPages = Math.ceil(count / limit)

  let from = 0
  let to = numberOfPages - 1
  let hasMorePagesPre = false
  let hasMorePagesPost = false

  if (numberOfPages > buttonLimit) {
    if (offset / limit + 1 <= Math.floor(buttonLimit / 2)) {
      // [x, x, x, ...]
      from = 0
      to = buttonLimit - 1
      hasMorePagesPost = true
    } else if (offset / limit + 1 >= numberOfPages - Math.floor(buttonLimit / 2)) {
      // [..., x, x, x]
      from = numberOfPages - buttonLimit
      to = numberOfPages - 1
      hasMorePagesPre = true
    } else {
      // [..., x, x, x, ...]
      from = offset / limit + 1 - Math.floor(buttonLimit / 2)
      to = offset / limit + Math.floor(buttonLimit / 2)
      hasMorePagesPre = true
      hasMorePagesPost = true
    }
  }

  return (
    <div className={cn(s.Pagination, variant === 'top' ? s.top : null)}>
      <Button
        small
        disabled={offset <= 0}
        onClick={() => onChange(offset - limit)}
        className={s.Button}
      >
        &lt;
      </Button>

      <When condition={hasMorePagesPre}>
        {/*
        <Button small onClick={() => onChange(0)} className={s.Button}>
          1
        </Button>
        */}
        <Button small className={s.Button}>
          &hellip;
        </Button>
      </When>

      {range(from, to + 1).map((i) => {
        return (
          <Button
            key={i}
            small
            onClick={() => onChange(i * limit)}
            color={offset / limit === i ? 'red' : null}
            className={s.Button}
          >
            {i + 1}
          </Button>
        )
      })}

      <When condition={hasMorePagesPost}>
        <Button small className={s.Button}>
          &hellip;
        </Button>
        {/*
        <Button small onClick={() => onChange((numberOfPages - 1) * limit)} className={s.Button}>
          {numberOfPages}
        </Button>
        */}
      </When>

      <Button
        small
        disabled={offset + limit >= count}
        onClick={() => onChange(offset + limit)}
        className={s.Button}
      >
        &gt;
      </Button>
    </div>
  )
}

export default Pagination
