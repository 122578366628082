/* global i18n */

import React from 'react'
import { If, Then, Else } from 'react-if'
import Button from '../../../../components/Button/Button.jsx'
import Tooltip from '../../../../components/Tooltip/Tooltip.jsx'

const ToggleVisibility = ({ photoId, onEnable, onDisable, isVisible }) => {
  return (
    <>
      <Tooltip label={i18n.t('photo_checkbox_desc')}>
        <If condition={isVisible}>
          <Then>
            <Button icon onClick={() => onDisable(photoId)}>
              <i className="fas fa-eye" />
            </Button>
          </Then>
          <Else>
            <Button icon onClick={() => onEnable(photoId)}>
              <i className="fas fa-eye-slash" />
            </Button>
          </Else>
        </If>
      </Tooltip>
    </>
  )
}

export default ToggleVisibility
