/* global xhrGet, xhrPost, baseUrl, isNil, isEmpty */

const apiGetPhotos = async (category, { search, limit, offset }) => {
  const query = { search, limit, offset, category }
  return {
    photos: await xhrGet(`${baseUrl}/web/blog/photos`, query),
    count: await xhrGet(`${baseUrl}/web/blog/count/photos`, query)
  }
}

const getPurchasablePhotos = async ({ sellerId, search, limit, offset }) => {
  const sector = document.getElementById('sector') ? parseInt(document.getElementById('sector').value) : null
  const query = { search, limit, offset, sector }
  return await xhrGet(`${baseUrl}/web/user/${sellerId ? `${sellerId}/` : ''}purchasable-photos`, query)
}

const createPhotoElement = (props) => {
  const { key, photoid, imageid, filename } = props
  const filenameToBlogPhotoPath = window.filenameToBlogPhotoPath || filenameToBlogPhotoPath
  const filenameToPhotoPath = window.filenameToPhotoPath || filenameToPhotoPath

  const fullUrl =
    state.photoModal.current === 'blog' ? filenameToBlogPhotoPath(filename) : filenameToPhotoPath(filename)

  const template = document.createElement('template')
  template.innerHTML = `
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div
        class="photo-element element w-100"
        data-key="${key}"
        data-photoid="${state.photoModal.current === 'blog' ? imageid : photoid}"
        style="background-image:url('${fullUrl}')"
      ></div>
      <button class="btn btn-red mt-2 view-voucher-info" data-photoid="${
        state.photoModal.current === 'blog' ? imageid : photoid
      }">
        ${i18n.t('global_view')}
      </button>
    </div>
  `

  const element = template.content.querySelector('.photo-element')
  element.addEventListener('click', () => {
    onPhotoSelect(element)
  })

  const buttons = Array.from(template.content.querySelectorAll('.view-voucher-info'))
  buttons.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      viewVoucherInfo(+e.target.getAttribute('data-photoid'))
    })
  })

  return template.content
}

const generateGridOfPhotos = (photos) => {
  const grid = document.createElement('div')
  grid.classList.add('grid')
  photos.forEach((photo, idx) => {
    const element = createPhotoElement({ ...photo, key: idx })
    element.children[0].classList.add('element')
    grid.appendChild(element)
  })
  return grid
}

const renderPhotoModal = ({ photos, count, data, totalCount }) => {
  const photoModal = window.photoModal || photoModal
  const state = window.state || state
  let container = document.getElementById(photoModal._.id)
  if (!isNil(container)) {
    document.body.removeChild(container)
  }

  const categoryMarkup = (current) => {
    if (current === 'uploaded') {
      return i18n.t('blog_photomodal_uploaded')
    } else if (current === 'blog') {
      return i18n.t('blog_photomodal_blog')
    } else if (current === 'purchased') {
      return i18n.t('blog_photomodal_purchased')
    } else if (current === 'purchasable-photos') {
      return i18n.t('blog_photomodal_uploaded')
    }
  }

  document.body.appendChild(
    photoModal.render({
      title: categoryMarkup(state.photoModal.current),
      okayLabel: i18n.t('global_select'),
      searchPlaceholder: i18n.t('photomodal_search_placeholder'),
      searchValue: state.photoModal.searchTerm,
      pagination: {
        count: count || totalCount,
        ...state.photoModal.pagination[state.photoModal.current]
      }
    })
  )

  container = document.getElementById(photoModal._.id)

  const modalContent = container.querySelector('.content')

  if (isEmpty(photos || data)) {
    if (isEmpty(state.photoModal.searchTerm)) {
      modalContent.textContent = i18n.t('photomodal_search_no_results')
    } else {
      modalContent.textContent = i18n.t('photomodal_search_no_results_for_searchterm', {
        searchTerm: state.photoModal.searchTerm
      })
    }
  } else {
    clearChildNodes(modalContent)
    modalContent.appendChild(generateGridOfPhotos(photos || data))
  }
}

const getSelectedPhoto = async () => {
  const selectedElement = document.querySelector(`#${photoModal._.id} .content .selected`)

  return {
    id: selectedElement.getAttribute(`data-photoid`),
    url: cssUrlToPath($(selectedElement).css('background-image'))
  }
}

const onPhotoSelect = (newlySelectedPhotoElement) => {
  document.querySelectorAll(`#${photoModal._.id} .content .selected`).forEach((photoElement) => {
    photoElement.classList.remove('selected')
  })

  newlySelectedPhotoElement.classList.add('selected')
}

const fetchPhotos = (windowState = false) => {
  if (!state || windowState) {
    var state = window.state
    state.photoModal.current = 'purchasable-photos'
    state.photoModal.pagination[state.photoModal.current] = {
      limit: window.paginationLimit,
      offset: 0
    }
  }
  return apiGetPhotos(state.photoModal.current, {
    search: state.photoModal.searchTerm,
    limit: state.photoModal.pagination[state.photoModal.current].limit,
    // a DB úgy kalkulálja az offset-et, hogy felszorozza a limittel, ezt itt "korrigálom"
    offset:
      state.photoModal.pagination[state.photoModal.current].offset /
      state.photoModal.pagination[state.photoModal.current].limit
  })
}

const fetchBlogImages = () => {
  return apiGetPhotos(state.photoModal.current, {
    search: state.photoModal.searchTerm,
    limit: state.photoModal.pagination[state.photoModal.current].limit,
    // a DB úgy kalkulálja az offset-et, hogy felszorozza a limittel, ezt itt "korrigálom"
    offset:
      state.photoModal.pagination[state.photoModal.current].offset /
      state.photoModal.pagination[state.photoModal.current].limit
  })
}

const changePhoto = async (softReload = false, blog = false) => {
  if (!softReload) {
    state.photoModal.searchTerm = null
  }
  state.photoModal.pagination[state.photoModal.current].offset = 0

  try {
    renderPhotoModal(blog ? await fetchBlogImages() : await fetchPhotos())

    const result = await photoModal.open()
    window.photoModal.close()
    if (result === true) {
      return await getSelectedPhoto()
    }
  } catch (e) {
    // console.warn(e)
  }

  return null
}

const changeBoughtPhoto = async (oldId) => {
  state.photoModal.current = 'purchasable-photos'
  state.photoModal.pagination[state.photoModal.current] = {
    limit: window.paginationLimit,
    offset: 0
  }
  state.photoModal.pagination[state.photoModal.current].offset = 0
  window.state = state
  try {
    renderPhotoModal(
      await getPurchasablePhotos({
        search: state.photoModal.searchTerm,
        limit: state.photoModal.pagination.limit,
        offset: state.photoModal.pagination.offset
      })
    )

    document.querySelector('#fullpage-loading').classList.add('hidden')
    const result = await photoModal.open()
    if (result) {
      await xhrPost(`${baseUrl}/web/photo/${oldId}/bought/${result}`)
      document.location.reload(true)
    }
    photoModal.close()
  } catch (e) {
    console.error(e)
  }

  return null
}

const activatePhoto = async (id) => {
  await xhrPost(`${baseUrl}/web/photo/${id}/activate`)
  document.location.reload(true)
}

const changeButtons = document.getElementsByClassName('change-photo')
Array.prototype.forEach.call(changeButtons, (button) => {
  button.addEventListener('click', async function () {
    document.querySelector('#fullpage-loading').classList.remove('hidden')
    await changeBoughtPhoto(button.getAttribute('data-photoid'))
  })
})

const activationButtons = document.getElementsByClassName('activate-photo')
Array.prototype.forEach.call(activationButtons, (button) => {
  button.addEventListener('click', async function () {
    await activatePhoto(button.getAttribute('data-photoid'))
  })
})

window.renderPhotoModal = renderPhotoModal
window.changeBoughtPhoto = changeBoughtPhoto
window.getPurchasablePhotos = getPurchasablePhotos
