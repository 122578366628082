/* global i18n */

import React, { useEffect } from 'react'
import { compose, values, flatten, length, filter, either, equals, path, pathEq } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import { hot, setConfig } from 'react-hot-loader'
import { useSelector, useDispatch } from 'react-redux'
import { If, Then, Else, When, Unless } from 'react-if'
import cn from 'classnames'
import { xhrGet } from '../../helpers/http.js'
import Title from '../../components/Title/Title.jsx'
import LoadingText from '../../components/LoadingText/LoadingText.jsx'
import { useEffectOnce } from '../../helpers/react.js'
import { baseUrl } from '../../common/constants.js'
import Items from './components/Items/Items.jsx'
import Total from './components/Total/Total.jsx'
import AbortPayment from './components/AbortPayment/AbortPayment.jsx'
import ClearCart from './components/ClearCart/ClearCart.jsx'
import DeveloperPay from './components/DeveloperPay/DeveloperPay.jsx'
import PayWithTronLink from './components/PayWithTronLink/PayWithTronLink.jsx'
import s from './style.module.scss'

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const Cart = ({ variation }) => {
  const mode = window.__initial_data__.mode

  const apiGetSettings = async () => {
    return xhrGet(`${baseUrl}/web/cart/settings`)
  }

  const apiGetCart = async () => {
    if (variation === 'history') {
      return xhrGet(`${baseUrl}/web/cart/history`)
    } else {
      return xhrGet(`${baseUrl}/web/cart`)
    }
  }

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.Cart.isLoading)
  const [counter, setCounter] = React.useState(90)
  const [paymentType, setPaymentType] = React.useState('1')
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Cart.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const setSettings = (settings) => {
    dispatch({
      type: 'Cart.setSettings',
      payload: {
        settings
      }
    })
  }

  const items = useSelector((state) => state.Cart.items)
  const setItems = (items) => {
    dispatch({
      type: 'Cart.setItems',
      payload: {
        items
      }
    })
  }

  const loadSettings = async () => {
    setIsLoading(true)
    try {
      const settings = await apiGetSettings()
      setSettings(settings)
    } catch (e) {
      console.error('Cart: loadSettings failed', await e.json())
    }
    setIsLoading(false)
  }

  const loadItems = async () => {
    setIsLoading(true)
    try {
      const items = await apiGetCart()
      setItems(items)
    } catch (e) {
      console.error('Cart: loadItems failed', e)
    }
    setIsLoading(false)
  }

  useEffectOnce(async () => {
    await loadSettings()
    await loadItems()
  })

  const flatItemList = flatten(values(items))
  const numberOfItems = length(flatItemList)

  const changesAreDue = compose(
    isNotEmpty,
    filter(compose(either(equals('ready to pay'), equals('payment pending')), path(['commerceinfo', 'statusText'])))
  )(flatItemList)

  const isUnderPayment = useSelector((state) => state.Cart.isUnderPayment)

  useEffect(() => {
    if (numberOfItems === 0 && isUnderPayment && counter > 0) {
      window.location = '/paid-complete'
    }
    const countertimer =
      numberOfItems > 0 &&
      isUnderPayment &&
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1)
      }, 1000)
    return () => clearInterval(countertimer)
  }, [counter, isUnderPayment, numberOfItems])

  useEffect(() => {
    if (changesAreDue) {
      const timer = setInterval(async () => {
        const items = await apiGetCart()
        setItems(items)
      }, 3000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [changesAreDue])

  useEffect(() => {
    if (variation !== 'history') {
      const cartNum = document.getElementById('cartnum')
      if (flatItemList.length === 0) {
        cartNum.style.visibility = 'hidden'
      } else {
        cartNum.style.visibility = 'visible'
        cartNum.textContent = flatItemList.length
      }
    }
  }, [flatItemList.length])

  const hasAbortableItems = compose(
    isNotEmpty,
    filter(pathEq(['commerceinfo', 'statusText'], 'ready to pay'))
  )(flatItemList)

  const paymentFail = isUnderPayment && counter === 0
  return (
    <>
      <Title>{i18n.t('cart_my_orders')}</Title>

      <LoadingText visible={isLoading} />

      <div className={cn('container', s.counter)} style={{ visibility: paymentFail ? 'visible' : 'hidden' }}>
        <div style={{ fontSize: '30px' }}>{i18n.t('payment_failed')}</div>
      </div>

      <If condition={numberOfItems > 0}>
        <Then>
          {() => {
            return (
              <>
                <When condition={variation !== 'history'}>
                  <div className={s.topButtons}>
                    <ClearCart />
                  </div>
                </When>
                <Items items={items.services} variation="service" isHistory={variation === 'history'} />
                <Items items={items.sectors} variation="sector" isHistory={variation === 'history'} />
                <Items items={items.photosForService} variation="photoForService" isHistory={variation === 'history'} />
                <Items items={items.photosForSector} variation="photoForSector" isHistory={variation === 'history'} />
                <Items items={items.photos} variation="photo" isHistory={variation === 'history'} />

                <When condition={variation !== 'history'}>
                  <div className={s.paymentSelection}>
                    <select
                      className={`${s.paymentSelectionSelect} form-control`}
                      id="payment-selection"
                      onChange={(event) => {
                        setPaymentType(event.target.value)
                      }}
                    >
                      <option value="1">Payment Type 1</option>
                      <option value="2">Payment Type 2</option>
                    </select>
                    <Total paymentType={paymentType} />
                  </div>

                  <div
                    className={cn('container', s.counter)}
                    style={{ visibility: !paymentFail && isUnderPayment && numberOfItems > 0 ? 'visible' : 'hidden' }}
                  >
                    <div style={{ fontSize: '30px' }}>{counter}s</div>
                    <div style={{ fontSize: '18px' }}>please wait...</div>
                  </div>

                  <div className={s.bottomButtons}>
                    <If condition={mode === 'development'}>
                      <Then>
                        <DeveloperPay style={{ float: 'left' }} />
                      </Then>
                      <Else>&nbsp;</Else>
                    </If>
                    <div className={s.primaryButtons}>
                      <Unless condition={isLoading || !hasAbortableItems || isUnderPayment}>
                        <p className={cn(s.pleaseDontLeavePageWilePaying, s.showOnDesktop)}>
                          {i18n.t('dont_leave_page_while_paying')}
                        </p>
                      </Unless>
                      <AbortPayment />
                      &nbsp;
                      <PayWithTronLink />
                    </div>
                  </div>

                  <div style={{ textAlign: 'right', marginTop: '20px', marginRight: '33px' }}>
                    {i18n.t('cart_pay_wallet_open')}
                  </div>

                  <Unless condition={isLoading || !hasAbortableItems || isUnderPayment}>
                    <p className={cn(s.pleaseDontLeavePageWilePaying, s.showOnMobile)}>
                      {i18n.t('dont_leave_page_while_paying')}
                    </p>
                  </Unless>
                  <p className={s.payWithTronlink}>{i18n.t('cart_pay_with_tronlink')}</p>
                </When>
              </>
            )
          }}
        </Then>
        <Else>
          <p className="center">{i18n.t(variation === 'history' ? 'purchase_history_empty' : 'cart_empty')}</p>
        </Else>
      </If>
    </>
  )
}

export default enhance(Cart)
