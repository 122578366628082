import React, { useState } from 'react'
import cn from 'classnames'
import { isEmpty } from 'ramda'
import { noop } from 'ramda-adjunct'
import Button from '../Button/Button.jsx'
import s from './style.module.scss'

const EllipsisButton = ({ items }) => {
  const [itemsVisible, setItemsVisible] = useState(false)

  const show = () => {
    setItemsVisible(true)
  }
  const hide = () => {
    setItemsVisible(false)
  }

  if (isEmpty(items)) {
    return null
  }

  return (
    <div className={s.EllipsisButton} onMouseOver={show} onMouseOut={hide}>
      <Button className={s.Button}>
        <i className="fas fa-ellipsis-h" />
      </Button>

      <div className={cn(s.items, itemsVisible ? s.active : null)}>
        {items.map((item, index) => {
          return (
            <div key={index} className={s.item} onClick={item.onClick ? item.onClick : noop}>
              {item.label}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EllipsisButton
