/* global i18n */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { flatten, values, compose, either, equals, any, path } from 'ramda'
import Button from '../../../../components/Button/Button.jsx'
import { xhrDelete } from '../../../../helpers/http.js'
import { baseUrl } from '../../../../common/constants.js'

const ClearCart = () => {
  const flatItemList = useSelector((state) => flatten(values(state.Cart.items)))

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.Cart.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Cart.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const setItems = (items) => {
    dispatch({
      type: 'Cart.setItems',
      payload: {
        items
      }
    })
  }

  const apiClearCart = async () => {
    return xhrDelete(`${baseUrl}/web/cart`)
  }

  const clearCart = async () => {
    setIsLoading(true)
    setItems(await apiClearCart())
    setIsLoading(false)
  }

  const canCartBeCleared = any(
    compose(
      either(equals('in cart'), equals('payment failed')),
      path(['commerceinfo', 'statusText'])
    )
  )(flatItemList)

  return (
    <Button disabled={isLoading || !canCartBeCleared} onClick={clearCart}>
      {i18n.t('cart_clear_cart')}
    </Button>
  )
}

export default ClearCart
