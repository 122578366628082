import React, { useState } from 'react'
import { compose } from 'ramda'
import { hot, setConfig } from 'react-hot-loader'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import { Unless } from 'react-if'
import LoadingText from '../../components/LoadingText/LoadingText.jsx'
import Title from '../../components/Title/Title.jsx'
import { xhrGet, xhrPost } from '../../helpers/http.js'
import Button from '../../components/Button/Button.jsx'
import { baseUrl } from '../../common/constants.js'
import { useEffectOnce } from '../../helpers/react.js'
import s from './style.module.scss'

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const DevSystemSettings = () => {
  const apiPostResetUsers = async () => {
    return xhrPost(`${baseUrl}/dev/api/system-settings/reset-users`)
  }

  const apiPostUpdateServer = async () => {
    return xhrPost(`${baseUrl}/dev/api/system-settings/update-server`)
  }

  const apiPostRegenerateImageHashForEveryPhoto = async (offset) => {
    return xhrPost(`${baseUrl}/dev/api/regenerate-image-hashes`, {
      offset
    })
  }

  const apiPostRegenerateImageThumbnails = async (offset) => {
    return xhrPost(`${baseUrl}/dev/api/regenerate-image-thumbnails`, {
      offset
    })
  }

  const apiPostRegenerateUserAvatars = async (offset) => {
    return xhrPost(`${baseUrl}/dev/api/regenerate-user-avatars`, {
      offset
    })
  }

  const apiCountImages = async () => {
    return xhrGet(`${baseUrl}/dev/api/count-images`)
  }

  const apiCountUsers = async () => {
    return xhrGet(`${baseUrl}/dev/api/count-users`)
  }

  const apiGetImageDuplicates = async () => {
    return xhrGet(`${baseUrl}/dev/api/image-duplicates`)
  }

  const dispatch = useDispatch()
  const [imageDuplicates, setImageDuplicates] = useState([])
  const [imageHashProgressPercent, setImageHashProgressPercent] = useState(null)
  const [imageThumbnailProgressPercent, setImageThumbnailProgressPercent] = useState(null)
  const [userAvatarProgressPercent, setUserAvatarProgressPercent] = useState(null)

  const isLoading = useSelector((state) => state.DevSystemSettings.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'DevSystemSettings.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const resetUsers = async () => {
    if (!confirm('This will remove all, but the system users. Are you sure you want to clear all users?')) {
      return
    }

    let message = null
    setIsLoading(true)
    try {
      const body = await apiPostResetUsers()
      message = body.message
    } catch (e) {
      const body = await e.json()
      message = body.messages.error
      console.error(`DevSystemSettings: resetUsers failed`, message)
    }
    setIsLoading(false)
    setTimeout(() => {
      alert(message)
    }, 10)
  }

  const updateServer = async () => {
    let message = null
    setIsLoading(true)
    try {
      const body = await apiPostUpdateServer()
      message = body.message
    } catch (e) {
      const body = await e.json()
      message = body.messages.error
      console.error(`DevSystemSettings: updateServer failed`, message)
    }
    setIsLoading(false)
    setTimeout(() => {
      alert(message)
    }, 10)
  }

  const generateImageHashForEveryPhoto = async () => {
    let message = null
    setIsLoading(true)
    try {
      const limit = 10
      const { count } = await apiCountImages()
      for (let i = 0; i < Math.ceil(count / limit); i++) {
        setImageHashProgressPercent((i / Math.ceil(count / limit)) * 100)
        await apiPostRegenerateImageHashForEveryPhoto(i * limit)
      }
      setImageHashProgressPercent(100)
      setImageDuplicates(await apiGetImageDuplicates())
    } catch (e) {
      const body = await e.json()
      message = body.messages.error
      console.error(`DevSystemSettings: generateImageHashForEveryPhoto failed`, message)
    }
    setIsLoading(false)
    if (message !== null && message !== '') {
      setTimeout(() => {
        alert(message)
      }, 10)
    }
  }

  const regenerateImageThumbnails = async () => {
    let message = null
    setIsLoading(true)
    try {
      const limit = 10
      const { count } = await apiCountImages()
      for (let i = 0; i < Math.ceil(count / limit); i++) {
        setImageThumbnailProgressPercent((i / Math.ceil(count / limit)) * 100)
        await apiPostRegenerateImageThumbnails(i * limit)
      }
      setImageThumbnailProgressPercent(100)
    } catch (e) {
      const body = await e.json()
      message = body.messages.error
      console.error(`DevSystemSettings: regenerateImageThumbnails failed`, message)
    }
    setIsLoading(false)
    if (message !== null && message !== '') {
      setTimeout(() => {
        alert(message)
      }, 10)
    }
  }

  const regenerateUserAvatars = async () => {
    let message = null
    setIsLoading(true)
    try {
      const limit = 10
      const { count } = await apiCountUsers()
      for (let i = 0; i < Math.ceil(count / limit); i++) {
        setUserAvatarProgressPercent((i / Math.ceil(count / limit)) * 100)
        await apiPostRegenerateUserAvatars(i * limit)
      }
      setUserAvatarProgressPercent(100)
    } catch (e) {
      const body = await e.json()
      message = body.messages.error
      console.error(`DevSystemSettings: regenerateUserAvatars failed`, message)
    }
    setIsLoading(false)
    if (message !== null && message !== '') {
      setTimeout(() => {
        alert(message)
      }, 10)
    }
  }

  useEffectOnce(async () => {
    setImageDuplicates(await apiGetImageDuplicates())
  })

  return (
    <>
      <Title>System Settings</Title>
      <LoadingText visible={isLoading} />
      <br />
      <Button onClick={() => updateServer()}>update server</Button>
      <br />
      <br />

      <hr />

      <h4>Image hash-ek</h4>
      <br />
      <Button onClick={() => generateImageHashForEveryPhoto()}>generate image hash</Button>
      <Unless condition={imageHashProgressPercent === null}>
        {() => <>&nbsp;- {imageHashProgressPercent === 100 ? 'done' : imageHashProgressPercent.toFixed(2) + ' %'}</>}
      </Unless>
      <br />
      <br />
      <table className={cn(s.table)}>
        <thead>
          <tr>
            <th>másolatok</th>
            <th>egyik photoid</th>
            <th>hash</th>
          </tr>
        </thead>
        <tbody>
          {imageDuplicates.map(({ count, photoId, imageHash }) => {
            return (
              <tr key={photoId}>
                <td>{count}</td>
                <td>{photoId}</td>
                <td>{imageHash}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />

      <hr />

      <h4>Image Thumbnail-ek</h4>
      <br />
      <Button onClick={() => regenerateImageThumbnails()}>regenerate image thumbnails</Button>
      <Unless condition={imageThumbnailProgressPercent === null}>
        {() => (
          <>
            &nbsp;- {imageThumbnailProgressPercent === 100 ? 'done' : imageThumbnailProgressPercent.toFixed(2) + ' %'}
          </>
        )}
      </Unless>
      <br />
      <br />

      <hr />

      <h4>User Avatar-ok</h4>
      <br />
      <Button onClick={() => regenerateUserAvatars()}>regenerate user avatars</Button>
      <Unless condition={userAvatarProgressPercent === null}>
        {() => <>&nbsp;- {userAvatarProgressPercent === 100 ? 'done' : userAvatarProgressPercent.toFixed(2) + ' %'}</>}
      </Unless>
      <br />
      <br />

      <hr />

      <br />
      <br />
      <Button onClick={() => resetUsers()}>total system reset</Button>
    </>
  )
}

export default enhance(DevSystemSettings)
