/* global i18n */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { flatten, pathEq, reject, values, compose } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import Button from '../../../../components/Button/Button.jsx'
import { xhrPost } from '../../../../helpers/http.js'
import { baseUrl } from '../../../../common/constants.js'

const DeveloperPay = () => {
  const flatItemList = useSelector((state) => flatten(values(state.Cart.items)))

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.Cart.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Cart.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const setItems = (items) => {
    dispatch({
      type: 'Cart.setItems',
      payload: {
        items
      }
    })
  }

  const apiDeveloperPay = async () => {
    return xhrPost(`${baseUrl}/web/cart/payment/developer`)
  }

  const developerPay = async () => {
    setIsLoading(true)
    setItems(await apiDeveloperPay())
    setIsLoading(false)
  }

  const hasDeveloperPayableItems = compose(
    isNotEmpty,
    reject(pathEq(['commerceinfo', 'statusText'], 'payment pending'))
  )(flatItemList)

  return (
    <Button disabled={isLoading || !hasDeveloperPayableItems} onClick={developerPay}>
      {i18n.t('cart_developer_pay')}
    </Button>
  )
}

export default DeveloperPay
