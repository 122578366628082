import React from 'react'
import cn from 'classnames'

const Title = ({ className, align = 'center', children, ...props }) => {
  return (
    <h2
      className={cn(className, {
        'text-center': align === 'center',
        'text-left': align === 'left',
        'text-right': align === 'right'
      })}
      {...props}
    >
      {children}
    </h2>
  )
}

export default Title
