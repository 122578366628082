/* global shownotify, hidenotify */

import React, { useRef } from 'react'

const Tooltip = ({ children, label, initiator = 'hover', inlineBlock = true }) => {
  const TooltipRef = useRef(null)

  return (
    <div
      ref={TooltipRef}
      style={{ display: inlineBlock ? 'inline-block' : 'block' }}
      {...{
        [initiator === 'hover' ? 'onMouseOver' : 'onClick']: () => {
          shownotify(TooltipRef.current, label)
        }
      }}
      onMouseOut={() => hidenotify()}
    >
      {children}
    </div>
  )
}

export default Tooltip
