import React from 'react'
import cn from 'classnames'
import s from './style.module.scss'

// TODO: valami ilyesmit https://gist.github.com/Gazouly/fb3a04414f7be946ccd0a310e1b1147f#file-defaultlayout_functionbased-js

const Modal = () => {
  return <div className={cn(s.Modal)}>modal</div>
}

export default Modal
