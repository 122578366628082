/* global i18n */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import cn from 'classnames'
import Button from '../../../../components/Button/Button.jsx'
import SearchBox from '../../../../components/SearchBox/SearchBox.jsx'
import { xhrGet } from '../../../../helpers/http.js'
import { baseUrl } from '../../../../common/constants.js'
import Contract from '../Contract/Contract.jsx'
import s from './style.module.scss'

const ContractSearchModal = (props, ref) => {
  const apiGetBuyers = async (photoId, searchTerm = '') => {
    const params = searchTerm === '' ? {} : { searchTerm }
    return xhrGet(`${baseUrl}/web/profile/buyers/${photoId}`, params)
  }

  const [photoId, setPhotoId] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [items, setItems] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  useImperativeHandle(ref, () => ({
    open: async (photoId) => {
      setPhotoId(photoId)
      setItems(await apiGetBuyers(photoId, searchTerm))
      setIsOpen(true)
    },
    close: () => {
      setIsOpen(false)
    }
  }))

  useEffect(async () => {
    if (isOpen) {
      setItems(await apiGetBuyers(photoId, searchTerm))
    }
  }, [searchTerm])

  return (
    <div className={cn('custommodal', s.ContractSearchModal, { hidden: !isOpen })}>
      <div className={cn('dialog', s.dialog)}>
        <div className={cn('header', s.header)}>
          <h3>{i18n.t('modal_header_history_sold')}</h3>
          <SearchBox onChange={setSearchTerm} />
        </div>
        <div className={cn('content', s.content)}>
          <div>
            <span>{i18n.t('global_id')}</span>
            <span>{i18n.t('global_name')}</span>
            <span>{i18n.t('purchase_date')}</span>
            <span />
          </div>
          {items.map((item, idx) => {
            return <Contract key={idx} photoId={photoId} {...item} />
          })}
        </div>
        <div className="footer">
          <Button
            onClick={() => {
              ref.current.close()
            }}
          >
            {i18n.t('global_close')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(ContractSearchModal)
