/* global i18n */
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  OKIcon,
  OKShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon
} from 'react-share'
import React from 'react'
import $ from 'jquery'

const SocialShare = ({
  url,
  copiedLabel = 'photo_copied',
  clickToCopyLabel = 'photo_click_to_copy',
  title = null,
  description = null,
  image = null
}) => {
  const selectedLanguage = window.__initial_data__.language

  function copyToClipboard(elem) {
    const target = elem

    // select the content
    const currentFocus = document.activeElement

    target.focus()
    target.setSelectionRange(0, target.value.length)

    // copy the selection
    let succeed

    try {
      succeed = document.execCommand('copy')
    } catch (e) {
      console.warn(e)

      succeed = false
    }

    // Restore original focus
    if (currentFocus && typeof currentFocus.focus === 'function') {
      currentFocus.focus()
    }

    if (succeed) {
      $('.copied').animate({ top: -25, opacity: 0 }, 700, function () {
        $(this).css({ top: 0, opacity: 1 })
      })
    }

    return succeed
  }

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <div className="d-flex mb-4">
        <FacebookShareButton url={url} title={title} description={description} image={image}>
          <FacebookIcon borderRadius={15} size={32} />
        </FacebookShareButton>
        <TwitterShareButton className="ml-2" url={url} title={title} description={description} image={image}>
          <XIcon borderRadius={15} size={32} />
        </TwitterShareButton>
        {selectedLanguage === 'ru' && (
          <>
            <VKShareButton className="ml-2" url={url} title={title} description={description} image={image}>
              <VKIcon borderRadius={15} size={32} />
            </VKShareButton>
            <OKShareButton className="ml-2" url={url} title={title} description={description} image={image}>
              <OKIcon borderRadius={15} size={32} />
            </OKShareButton>
          </>
        )}
        <LinkedinShareButton className="ml-2" url={url} title={title} description={description} image={image}>
          <LinkedinIcon borderRadius={15} size={32} />
        </LinkedinShareButton>
        <TumblrShareButton className="ml-2" url={url} title={title} description={description} image={image}>
          <TumblrIcon borderRadius={15} size={32} />
        </TumblrShareButton>
        <RedditShareButton className="ml-2" url={url} title={title} description={description} image={image}>
          <RedditIcon borderRadius={15} size={32} />
        </RedditShareButton>
        <TelegramShareButton className="ml-2" url={url} title={title} description={description} image={image}>
          <TelegramIcon borderRadius={15} size={32} />
        </TelegramShareButton>
        <WhatsappShareButton className="ml-2" url={url} title={title} description={description} image={image}>
          <WhatsappIcon borderRadius={15} size={32} />
        </WhatsappShareButton>
        <EmailShareButton className="ml-2" url={url} title={title} description={description} image={image}>
          <EmailIcon borderRadius={15} size={32} />
        </EmailShareButton>
      </div>
      <div className="copy__input d-flex">
        <input
          onFocus={(e) => copyToClipboard(e.currentTarget)}
          type="text"
          id="copyTarget"
          className="form-control"
          value={url}
          readOnly
        />
        <span
          onClick={() => document.getElementById('copyTarget').focus()}
          id="copyButton"
          className="btn"
          title={i18n.t(clickToCopyLabel)}
        >
          <i className="far fa-copy" />
        </span>
        <span className="copied">{i18n.t(copiedLabel)}</span>
      </div>
    </div>
  )
}

export default SocialShare
