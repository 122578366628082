/* global i18n */

import React, { useRef } from 'react'
import { hot, setConfig } from 'react-hot-loader'
import { compose, isEmpty } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import { When } from 'react-if'
import { useEffectOnce } from '../../helpers/react.js'
import LoadingText from '../../components/LoadingText/LoadingText.jsx'
import { xhrGet } from '../../helpers/http.js'
import { baseUrl } from '../../common/constants.js'
import ContractSearchModal from './components/ContractSearchModal/ContractSearchModal.jsx'
import Photo from './components/Photo/Photo.jsx'

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const HistorySold = () => {
  const apiGetHistorySold = async () => {
    // TODO: pagination
    return xhrGet(`${baseUrl}/web/profile/historysold`)
  }

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.HistorySold.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'HistorySold.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const photos = useSelector((state) => state.HistorySold.photos)
  const setPhotos = (photos) => {
    dispatch({
      type: 'HistorySold.setPhotos',
      payload: {
        photos
      }
    })
  }

  const ContractSearchModalRef = useRef(null)

  const getHistorySold = async () => {
    setIsLoading(true)
    try {
      const data = await apiGetHistorySold()
      setPhotos(data.photos)
    } catch (e) {
      console.error('HistorySold: getHistorySold failed', e)
    }
    setIsLoading(false)
  }

  useEffectOnce(() => {
    getHistorySold()
  })

  return (
    <>
      <ContractSearchModal ref={ContractSearchModalRef} />

      <LoadingText visible={isLoading} />

      <div className="row">
        {photos.map((photo) => (
          <Photo
            key={photo.photoid}
            {...photo}
            onContractListClick={() => {
              ContractSearchModalRef.current.open(photo.photoid)
            }}
          />
        ))}
      </div>

      <When condition={!isLoading && isEmpty(photos)}>
        <div style={{ textAlign: 'center' }}>
          <strong>{i18n.t('history_sold_empty')}</strong>
        </div>
      </When>
    </>
  )
}

export default enhance(HistorySold)
