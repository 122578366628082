/* global i18n */

import React, { useEffect, useState } from 'react'
import { compose } from 'ramda'
import { hot } from 'react-hot-loader'
import s from './style.module.scss'

const enhance = compose(hot(module))

const DiscountCalculate = () => {
  const [price, setPrice] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [discountPrice, setDiscountPrice] = useState(0)
  const [discountedPrice, setDiscountedPrice] = useState(0)

  useEffect(() => {
    const discounted = (price * discount) / 100
    setDiscountPrice(discounted)
    setDiscountedPrice(price - discounted)
  }, [price, discount])

  const handlePriceChange = (e) => {
    let value = Number(e.target.value)
    if (value <= 0) {
      value = 0
    }
    setPrice(value)
  }

  const handleDiscountChange = (e) => {
    let value = Number(e.target.value)
    if (value <= 0) {
      value = 0
    }
    if (value >= 100) {
      value = 100
    }
    setDiscount(value)
  }

  return (
    <div className={s.calculator}>
      <div className={s.form}>
        <label>
          <span className={s.form__label}>{i18n.t('calculator_price_label')}:</span>
          <input className="form-control" type="number" value={price} onChange={handlePriceChange} />
        </label>
        <label>
          <span className={s.form__label}>{i18n.t('calculator_discount_label')}:</span>
          <input className="form-control" type="number" value={discount} onChange={handleDiscountChange} />
        </label>
      </div>
      {price !== 0 && discount !== 0 && (
        <div className={s.resultBox}>
          <h3 className={s.resultBox__title}>{i18n.t('calculator_price_result')}</h3>
          <span className={s.resultBox__result}>{discountedPrice.toFixed(2)}</span>
          <h4 className={[s.resultBox__title, s.resultBox__title_small]}>{i18n.t('calculator_discount_result')}</h4>
          <span className={[s.resultBox__result, s.resultBox__result_small]}>{discountPrice.toFixed(2)}</span>
        </div>
      )}
    </div>
  )
}

export default enhance(DiscountCalculate)
