/* global xhrGet, xhrPatch, baseUrl, i18n, isNil, $, isEmpty, state, photoModal, clearChildNodes, cssUrlToPath */
/* global showFullpageLoading, hideFullpageLoading, viewVoucherInfo */

const getPurchasablePhotos = async ({ sellerId, sellerName, sellerAvatar, search, limit, offset }) => {
  const sector = document.getElementById('sector') ? parseInt(document.getElementById('sector').value) : null
  const service = document.getElementById('serviceid') ? parseInt(document.getElementById('serviceid').value) : null
  const query = { search, limit, offset, sector , service }

  return {
    ...(await xhrGet(`${baseUrl}/web/user/${sellerId ? `${sellerId}/` : ''}purchasable-photos`, query)),
    sellerId,
    sellerName,
    sellerAvatar
  }
}

const createPhotoElement = (props) => {
  const { photoid, name, thumbnail } = props

  const template = document.createElement('template')
  template.innerHTML = `
<div class="d-flex flex-column align-items-center justify-content-center">
  <div
    class="photo-element element w-100"
    data-photoid="${photoid}"
    data-name="${name}"
    style="background-image:url('${thumbnail}')"
  ></div>
  <button class="btn btn-red mt-2 view-voucher-info" data-photoid="${photoid}">
     ${i18n.t('global_view')}
  </button>
</div>
  `

  const element = template.content.querySelector('.photo-element')
  element.addEventListener('click', () => {
    onPhotoSelect(element)
  })

  return template.content
}

const generateGridOfPhotos = (photos) => {
  const grid = document.createElement('div')
  grid.classList.add('grid')
  photos.forEach((photo) => {
    const element = createPhotoElement(photo)
    element.children[0].classList.add('element')
    grid.appendChild(element)
  })

  const buttons = Array.from(grid.getElementsByClassName('view-voucher-info'))
  buttons.forEach((button) =>
    button.addEventListener('click', (e) => viewVoucherInfo(+e.currentTarget.getAttribute('data-photoid')))
  )

  return grid
}

const renderPhotoModal = ({ photos, count, data, totalCount, sellerId, sellerName, sellerAvatar }) => {
  let container = document.getElementById(photoModal._.id)

  if (!isNil(container)) {
    document.body.removeChild(container)
  }

  document.body.appendChild(
    photoModal.render({
      title: i18n.t('office_global_change_photo'),
      okayLabel: i18n.t('photo_modal_submit_label'),
      searchPlaceholder: i18n.t('photomodal_search_placeholder'),
      searchValue: state.photoModal.searchTerm,
      sellerId,
      sellerName,
      sellerAvatar,
      pagination: {
        count: count || totalCount,
        ...state.photoModal.pagination
      }
    })
  )

  container = document.getElementById(photoModal._.id)

  const modalContent = container.querySelector('.content')

  if (isEmpty(photos || data)) {
    if (isEmpty(state.photoModal.searchTerm)) {
      modalContent.textContent = i18n.t('photomodal_search_no_results')
    } else {
      modalContent.textContent = i18n.t('photomodal_search_no_results_for_searchterm', {
        searchTerm: state.photoModal.searchTerm
      })
    }
  } else {
    clearChildNodes(modalContent)
    modalContent.appendChild(generateGridOfPhotos(photos || data))
  }

  const selectedPhoto = document.querySelector(`#${photoModal._.id} .content .selected`)
  const submitBtn = document.querySelector(`#${photoModal._.id} button[type="submit"]`)
  submitBtn.disabled = selectedPhoto === null
}

const updateMainPageWithNewImage = () => {
  document.location.reload(true)
}

const saveNewPhoto = async () => {
  const selectedElement = document.querySelector(`#${photoModal._.id} .content .selected`)
  const idx = state.photoModal.photoIndexToChange
  const position = state.photoModal.position
  const sector = document.getElementById('sector') ? parseInt(document.getElementById('sector').value) : null
  const serviceId = document.getElementById('serviceid') ? parseInt(document.getElementById('serviceid').value) : null
  const photoId = document.getElementById(`photo_${idx}_${position}`)
    ? parseInt(document.getElementById(`photo_${idx}_${position}`).getAttribute('data-photoid'))
    : null
  const randId = document.getElementById(`photo_${idx}_${position}`)
      ? document.getElementById(`photo_${idx}_${position}`).getAttribute('data-randid')
      : null

  await xhrPatch(`${baseUrl}/web/${sector ? 'sector' : 'service'}/random-photo`, {
    seller: idx,
    photoId,
    randId,
    position: position,
    newPhotoId: selectedElement.getAttribute('data-photoid'),
    ...(sector ? { sector } : { serviceId })
  })

  updateMainPageWithNewImage()
}

const onPhotoSelect = (newlySelectedPhotoElement) => {
  document.querySelectorAll(`#${photoModal._.id} .content .selected`).forEach((photoElement) => {
    photoElement.classList.remove('selected')
  })

  newlySelectedPhotoElement.classList.add('selected')

  const selectedPhoto = document.querySelector(`#${photoModal._.id} .content .selected`)
  const submitBtn = document.querySelector(`#${photoModal._.id} button[type="submit"]`)
  submitBtn.disabled = selectedPhoto === null
}

const fetchPhotos = async () => {
  const idx = state.photoModal.photoIndexToChange
  const sellerObject = document.querySelector(`[data-randomuser-idx="${idx}"]`)
  const sellerId = sellerObject?.getAttribute('data-randomuser-id')
  const sellerName = sellerObject?.querySelector('.sellername').innerText
  const sellerAvatar = sellerObject?.querySelector('.avatarimage').getAttribute('src')

  return getPurchasablePhotos({
    sellerId: sellerId,
    sellerName,
    sellerAvatar,
    search: state.photoModal.searchTerm,
    limit: state.photoModal.pagination.limit,
    offset: state.photoModal.pagination.offset
  })
}

const changePhoto = async (photoIndex, position) => {
  showFullpageLoading()
  state.photoModal.photoIndexToChange = photoIndex
  state.photoModal.position = position
  state.photoModal.searchTerm = null
  state.photoModal.pagination.offset = 0

  try {
    renderPhotoModal(await fetchPhotos())
    hideFullpageLoading()

    const result = await photoModal.open()

    if (result) {
      showFullpageLoading()
      await saveNewPhoto()
    }
    photoModal.close()
  } catch (e) {
    hideFullpageLoading()
    console.error('changePhoto failed', e)
  }
}

function showvouchermodal(id) {
  $(`#vouchermodal-${id}`).modal('show', {})
}

window.showvouchermodal = showvouchermodal
window.changePhoto = changePhoto
window.fetchPhotos = fetchPhotos
