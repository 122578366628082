/* global i18n */

import React from 'react'
import { baseUrl } from '../../../../common/constants.js'
import Name from '../../../../components/Name/Name.jsx'

const Contract = ({ photoId, userId, firstName, lastName, userName, purchasedAt }) => {
  const locale = window.__initial_data__.language
  const formattedDate = purchasedAt.split(' ')[0]
  return (
    <div>
      <span>{userId}</span>
      <span>
        <Name {...{ firstName, lastName, userName }} />
      </span>
      <span>{formattedDate}</span>
      <span>
        <a href={`${baseUrl}/download-purchased-contract/${locale}/${photoId}/${userId}`}>
          {i18n.t('global_download')}
        </a>
      </span>
    </div>
  )
}

export default Contract
