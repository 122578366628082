class Pagination extends EventEmitter3 {
  render(props) {
    const { count, limit, offset } = props

    const num = Math.ceil(count / limit)
    const current = Math.ceil(offset / limit) + 1

    const template = document.createElement('template')
    template.innerHTML = `
      <div class="pagination">
        <button type="button" class="prev"${current === 1 ? ' disabled' : ''}>&lt;</button>
        ${range(1, num + 1)
          .map((idx) => {
            return `<button type="button"${
              current === idx ? ' class="active" disabled' : ''
            }>${idx}</button>`
          })
          .join('\n')}
        <button type="button" class="next"${current === num ? ' disabled' : ''}>&gt;</button>
      </div>
    `

    template.content.querySelectorAll('button').forEach((button) => {
      button.addEventListener('click', (e) => {
        const classes = e.target.classList
        if (classes.contains('prev')) {
          this.emit('change', current - 1)
          return
        }
        if (classes.contains('next')) {
          this.emit('change', current + 1)
          return
        }
        this.emit('change', parseInt(e.target.textContent.trim()))
      })
    })

    return template.content
  }
}

window.Pagination = Pagination
