import React from 'react'
import cn from 'classnames'
import s from './style.module.scss'

const Button = ({ color, icon, small, nowrap, children, className, ...props }) => {
  const colorClass = s[color]

  /*
    // TODO: befejezni ezt (PH1-T874)

    állapotok:
    - normál
    - hover
    - disabled

    ikon:
    - bal oldalt
    - nincs
    - csak ikon van szöveg nélkül

    szélesség:
    - auto
    - fix (minden középen van)

    tooltip:
    - van
    - nincs

    variáció:
    - pagination gomb
  */

  return (
    <button
      type="button"
      className={cn(s.Button, colorClass, className, {
        [s.small]: small !== undefined,
        [s.icon]: icon !== undefined,
        [s.nowrap]: nowrap !== undefined
      })}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
