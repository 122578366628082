/* global i18n */

import React from 'react'
import { useSelector } from 'react-redux'
import { flatten, values, reduceBy, prop, pluck, compose, toPairs } from 'ramda'
import { If } from 'react-if'
import s from './style.module.scss'

const Total = ({ paymentType }) => {
  const flatItemList = useSelector((state) => flatten(values(state.Cart.items)))
  const settings = useSelector((state) => state.Cart.settings)

  /*
  input = [
    {currency: 'USD', amount: 20, ...},
    {currency: 'HUF', amount: 4000, ...},
    {currency: 'USD', amount: 20, ...},
    {currency: 'USD', amount: 20, ...}
  ]
  output = {"USD": 60, "HUF": 4000}
  */

  const groupAmountsByCurrency = reduceBy((acc, { amount }) => acc + amount, 0, prop('currency'))

  const prices = compose(toPairs, groupAmountsByCurrency, pluck('commerceinfo'))(flatItemList)
  let fullprice = 0

  for (const [, amount] of prices) {
    fullprice += amount
  }
  fullprice *= settings.tronPriceUSD
  fullprice = fullprice.toFixed(6)
  fullprice += ' TRX '
  return (
    <div className={s.Total}>
      <h4>{i18n.t('cart_total_amount')}</h4>
      <div className={s.prices}>
        {prices.map(([currency, amount], idx) => (
          <div className={s.price} key={idx}>
            {amount} {currency}
          </div>
        ))}
        <div className={s.plusFee}>
          <If condition={paymentType === '1'}>
            <div>This is the 1. paymentType</div>
          </If>
          <If condition={paymentType === '2'}>
            <div>This is the 2. paymentType</div>
          </If>
          {fullprice} {i18n.t('cart_fee')}
        </div>
      </div>
    </div>
  )
}

export default Total
