import React, { useState } from 'react'
import cn from 'classnames'
import Button from '../Button/Button.jsx'
import s from './style.module.scss'

const SearchBox = ({ onChange, className, ...props }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleOnChange = (e) => {
    setSearchTerm(e.target.value)
    onChange(e.target.value)
  }

  const clearSearchBar = () => {
    setSearchTerm('')
    onChange('')
  }

  return (
    <div className={cn(s.SearchBox, className)} {...props}>
      <input type="search" value={searchTerm} onChange={handleOnChange} />
      <Button className={s.closeBtn} small icon onClick={clearSearchBar}>
        <i className="fas fa-times" />
      </Button>
    </div>
  )
}

export default SearchBox
