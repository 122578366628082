/* global i18n */

import React from 'react'
import { useDispatch } from 'react-redux'
// import { When } from 'react-if'
import { baseUrl } from '../../../../common/constants.js'
import Button from '../../../../components/Button/Button.jsx'
import { getMonth, getMonthName, getYear, getDay } from '../../../../helpers/date.js'
import { xhrDelete } from '../../../../helpers/http.js'

import s from './style.module.scss'

const renderDate = (timestamp) => {
  const year = getYear(timestamp)
  const month = getMonthName(getMonth(timestamp))
  const day = getDay(timestamp)

  return `${month} ${day}, ${year}`
}

const aDayInSeconds = 86400

/*
const getTronInfo = (variation, props) => {
  let troninfo

  switch (variation) {
    case 'service':
      troninfo = { startdate:  props.date }
      break
      case 'photoForService':
        troninfo = { startdate: new Date(props.service.validFrom).getTime() / 1000,usertronaddress:props.usertronaddress }
        break
  }

  return troninfo
}
*/

const getDescription = (variation, props) => {
  let description = ''

  switch (variation) {
    case 'service':
      {
        const { date: validFrom } = props
        const validTo = validFrom + aDayInSeconds * 30

        const from = renderDate(validFrom)
        const to = renderDate(validTo)

        description = `${i18n.t('global_service')}: ${from} - ${to}`
      }
      break
    case 'sector':
      {
        const { sector } = props
        description = `${i18n.t('global_sector')} ${sector}`
      }
      break
    case 'photoForService':
      {
        const { photoid } = props
        description = `${i18n.t('photo_id')} ${photoid}`
      }
      break
    case 'photoForSector':
      {
        const { photoid, sector } = props
        description = `${i18n.t('photo_id')} ${photoid}}: ${sector}`
      }
      break
    case 'photo': {
      const { photoid } = props
      description = `${i18n.t('photo_id')} ${photoid}`
    }
  }

  return description
}

const Item = ({ commerceinfo, variation, isHistory, ...props }) => {
  const { commerceid, statusText, amount, currency, updated_at } = commerceinfo

  const description = getDescription(variation, props)

  // const troninfo = getTronInfo(variation, props)

  // const tronLink = window.tronWeb !== undefined

  const apiRemoveFromCart = async (commerceId) => {
    return xhrDelete(`${baseUrl}/web/cart/${commerceId}`)
  }

  const dispatch = useDispatch()

  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Cart.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const setItems = (items) => {
    dispatch({
      type: 'Cart.setItems',
      payload: {
        items
      }
    })
  }

  const removeFromCart = async () => {
    setIsLoading(true)
    const items = await apiRemoveFromCart(commerceid)
    setItems(items)
    setIsLoading(false)
  }

  const price = `${amount} ${currency}`

  const isRemovable = statusText === 'in cart' || statusText === 'payment failed'

  return (
    <div className={s.Item}>
      <span className={s.commerceId}>#{commerceid}</span>
      <span className={s.description}>{description}</span>
      <span className={s.price}>{price}</span>
      <span className={s.status}>
        {statusText}
        {variation === 'photo' && isRemovable && (
          <span>
            <Button onClick={removeFromCart} icon>
              <i className="fas fa-trash" />
            </Button>
          </span>
        )}
      </span>

      {isHistory && <span className={s.updatedAt}>{updated_at}</span>}
    </div>
  )
}

export default Item
