import React from 'react'
import cn from 'classnames'
import s from './style.module.scss'

const Separator = ({ horizontal = false, vertical = false, className, ...props }) => {
  const direction = !horizontal && vertical ? 'vertical' : 'horizontal'
  return (
    <hr
      className={cn(s.Separator, className, {
        [s.horizontal]: direction === 'horizontal',
        [s.vertical]: direction === 'vertical'
      })}
      {...props}
    />
  )
}

export default Separator
