/* global Modal, fetchPhotos, fetchUsers, renderPhotoModal, renderUserModal, Demo */
/* global showFullpageLoading, hideFullpageLoading */

const paginationLimit = 10

const state = {
  photoModal: {
    photoIndexToChange: null,
    searchTerm: null,
    pagination: {
      limit: paginationLimit,
      offset: 0
    }
  },
  userModal: {
    userIndexToChange: null,
    searchTerm: null,
    pagination: {
      limit: paginationLimit,
      offset: 0
    },
    customParams: {
      showInactiveUsers: true
    }
  }
}

// kell a globális referencia, mert a többi fájl erre akasztja az event-eket
let photoModal
let voucherInfoModal
let confirmationModal
let userModal

switch (window.location.pathname) {
  case `/demo`:
  case `/weboffice/demo`:
    {
      const initialDemo = document.getElementById('demo')
      Demo.hydrate(initialDemo)
    }
    break
  default: {
    photoModal = new Modal()
    photoModal.on('search', async (searchTerm) => {
      state.photoModal.searchTerm = searchTerm
      state.photoModal.pagination.offset = 0
      showFullpageLoading()
      if (window.state.photoModal.current === 'purchasable-photos') {
        window.renderPhotoModal(
          await window.getPurchasablePhotos({
            search: window.state.photoModal.searchTerm,
            limit: window.state.photoModal.pagination.limit,
            offset: window.state.photoModal.pagination.offset
          })
        )
      } else {
        renderPhotoModal(await fetchPhotos())
      }
      hideFullpageLoading()
    })
    photoModal.on('go to page', async (page) => {
      state.photoModal.pagination.offset = (page - 1) * state.photoModal.pagination.limit
      showFullpageLoading()
      if (window.state.photoModal.current === 'purchasable-photos') {
        window.state.photoModal.pagination[state.photoModal.current].offset =
          (page - 1) * window.state.photoModal.pagination[[state.photoModal.current]].limit
        window.renderPhotoModal(
          await window.getPurchasablePhotos({
            search: window.state.photoModal.searchTerm,
            limit: window.state.photoModal.pagination.limit,
            offset: window.state.photoModal.pagination.offset
          })
        )
      } else {
        renderPhotoModal(await fetchPhotos())
      }
      hideFullpageLoading()
    })

    // ---------------

    userModal = new Modal()
    userModal.on('search', async (searchTerm) => {
      state.userModal.searchTerm = searchTerm
      state.userModal.pagination.offset = 0
      showFullpageLoading()
      renderUserModal(await fetchUsers())
      hideFullpageLoading()
    })
    userModal.on('go to page', async (page) => {
      state.userModal.pagination.offset = (page - 1) * state.userModal.pagination.limit
      showFullpageLoading()
      renderUserModal(await fetchUsers())
      hideFullpageLoading()
    })

    // ---------------

    voucherInfoModal = new Modal()

    // ---------------

    confirmationModal = new Modal()
  }
}

window.photoModal = photoModal
window.userModal = userModal
window.voucherInfoModal = voucherInfoModal
window.confirmationModal = confirmationModal
window.state = state
window.paginationLimit = paginationLimit
