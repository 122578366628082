/* global i18n, classnames, EventEmitter3, baseUrl, Pagination, generateUniqId */
/* global isNil */

class Modal extends EventEmitter3 {
  constructor() {
    super()
    this._ = {
      id: 'modal-' + generateUniqId(),
      shown: false,
      resolver: null,
      rejecter: null,
      pagination: new Pagination()
    }

    this._.pagination.on('change', (page) => {
      this.emit('go to page', page)
    })
  }

  render(props = {}) {
    const { shown, id } = this._
    const {
      title = '',
      okayLabel = i18n.t('global_apply'),
      cancelLabel = i18n.t('global_cancel'),
      okayAction = true,
      cancelAction = true,
      additionalButton = false,
      sellerName = '',
      sellerAvatar = '',
      sellerId = -1,
      additionalLabel = '',
      searchPlaceholder = i18n.t('global_ssearch'),
      searchValue = '',
      pagination = {},
      width = '80vw',
      height = '80vh',
      searchEnabled = true,
      btnClass = ''
    } = props

    const features = {
      search: true,
      ...props.features
    }

    const template = document.createElement('template')
    template.innerHTML = `
      <div class="${classnames('custommodal', shown ? '' : 'hidden')}" id="${id}">
        <div class="dialog" style="width:${width};height:${height};left:calc((100vw - ${width}) / 2);top:calc((100vh - ${height}) / 2)">
          <div class="header d-flex align-items-center">
            <h3>${title}</h3>
            ${sellerName ? 
      `        <div style="display:flex;flex-direction:row;align-items:center;margin-left:12px;">
                        <a href="${baseUrl}/user/${sellerId}">
                            <img class="avatarimage" style="margin-right:10px" src="${sellerAvatar}" width="40" height="40">
                        </a>
                        <strong style="font-size:15px">${sellerName}</strong>
                    </div>
            ` : ''}
            ${
              searchEnabled
                ? `
            <div class="search ml-auto pt-0 ${features.search === false ? 'hidden' : ''}">
              <input type="search" placeholder="${searchPlaceholder}" value="${
                    isNil(searchValue) ? '' : searchValue
                  }" />
              <button type="button">${i18n.t('global_ssearch')}</button>
            </div>`
                : ''
            }
          </div>
          <div class="content"></div>
          <div class="footer">
            ${cancelAction ? `<button type="button" class="cancel">${cancelLabel}</button>` : ''}
            ${additionalButton ? `<button type="submit" class="additional okay">${additionalLabel}</button>` : ''}
            ${okayAction ? `<button type="submit" class="${btnClass} okay">${okayLabel}</button>` : ''}
          </div>
        </div>
        <div class="background-fade"></div>
      </div>
    `

    template.content.querySelector('.background-fade').addEventListener('click', () => {
      this._.resolver(false)
      this._.resolver = null
      this._.rejecter = null
    })
    template.content.querySelector('.cancel')?.addEventListener('click', () => {
      this._.resolver(false)
      this._.resolver = null
      this._.rejecter = null
    })
    template.content.querySelector('.okay:not(.additional)')?.addEventListener('click', () => {
      this._.resolver(document.querySelector('.photo-element.selected')?.getAttribute('data-photoid') ?? true)
      this._.resolver = null
      this._.rejecter = null
    })
    template.content.querySelector('.additional')?.addEventListener('click', () => {
      this._.resolver(document.querySelector('.photo-element.selected')?.getAttribute('data-photoid') ?? 'additional')
      this._.resolver = null
      this._.rejecter = null
    })

    if (features.search) {
      const searchInput = template.content.querySelector('.search input')
      searchInput?.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          this.emit('search', searchInput.value.trim())
        }
      })
      template.content.querySelector('.search button')?.addEventListener('click', () => {
        this.emit('search', searchInput?.value.trim())
      })
    }

    if (pagination.count > pagination.limit) {
      const footer = template.content.querySelector('.footer')
      footer.insertBefore(this._.pagination.render(pagination), footer.firstChild)
    }

    return template.content
  }

  open() {
    const { shown, id } = this._

    if (!shown) {
      this._.shown = true

      document.body.classList.add('no-scroll')
      document.getElementById(id).classList.remove('hidden')
      if (!isNil(this._.rejecter)) {
        this._.rejecter(new Error(`Previous modal didn't close properly, force closing it now`))
      }
      return new Promise((resolve, reject) => {
        this._.resolver = resolve
        this._.rejecter = reject
      })
    }
  }

  close() {
    const { shown, id } = this._

    if (shown) {
      this._.shown = false

      document.body.classList.remove('no-scroll')
      document.getElementById(id).classList.add('hidden')
    }
  }
}

window.Modal = Modal
