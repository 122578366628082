/* eslint-disable linebreak-style */
/* eslint-disable prettier/prettier */
/* global i18n */

import React, {useState} from 'react'
import {hot, setConfig} from 'react-hot-loader'
import {compose} from 'ramda'
import {useDispatch, useSelector} from 'react-redux'
import {useEffectOnce} from '../../helpers/react.js'
import Title from '../../components/Title/Title.jsx'
import {xhrGet} from '../../helpers/http.js'
import {baseUrl} from '../../common/constants.js'
import ServiceSelector from '../Activation/components/ServiceSelector/ServiceSelector.jsx';
import StatusIndicator from '../Activation/components/StatusIndicator/StatusIndicator.jsx';

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const SubsequentActivation = () => {
  const [isCompanyUser, setIsCompanyUser] = useState(false);

  const apiGetServices = async () => {
    return xhrGet(`${baseUrl}/web/services`)
  }

  const apiGetCurrentService = async () => {
    return xhrGet(`${baseUrl}/web/service/current`)
  }

  const dispatch = useDispatch()

  // const isLoading = useSelector((state) => state.Activation.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Activation.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const services = useSelector((state) => state.Activation.services)
  const setServices = (services) => {
    dispatch({
      type: 'Activation.setServices',
      payload: {
        services
      }
    })
  }

  const setSelectedServiceId = (selectedServiceId) => {
    dispatch({
      type: 'Activation.setSelectedServiceId',
      payload: {
        selectedServiceId
      }
    })
  }

  const loadServices = async () => {
    setIsLoading(true)
    try {
      const {services, isCompanyUser} = await apiGetServices()
      const {serviceId} = await apiGetCurrentService()
      setServices(services)
      setIsCompanyUser(isCompanyUser)
      setSelectedServiceId(serviceId)
    } catch (e) {
      console.error('Activation: loadServices failed', await e.json())
    }
    setIsLoading(false)
  }

  useEffectOnce(() => {
    loadServices()
  })


  return (
    <div>
      <Title align="left" style={{ display: 'inline-block', marginTop: '0.25em' }}>
        {i18n.t('office_next_activation_header')}
      </Title>
      <ServiceSelector services={services} isCompanyUser={isCompanyUser} subsequent />
      <StatusIndicator />
    </div>
  )
}

export default enhance(SubsequentActivation)
