import React from 'react'
import cn from 'classnames'
import s from './style.module.scss'

const StatusIndicator = ({ /* hasService, */ className, ...props }) => {
  return (
    <div className={cn(s.StatusIndicator, className)} {...props}>
      {/* TODO */}
    </div>
  )
}

export default StatusIndicator
