/* global i18n */

import React from 'react'
import { isEmpty } from 'ramda'
import { Case, Switch } from 'react-if'
import Item from '../Item/Item.jsx'
import s from './style.module.scss'

const Items = ({ items, variation, isHistory }) => {
  if (isEmpty(items)) {
    return null
  }

  return (
    <div className={s.Items}>
      <h3 className={s.title}>
        <Switch>
          <Case condition={variation === 'service'}>{i18n.t('global_service')}</Case>
          <Case condition={variation === 'sector'}>{i18n.t('global_sector')}</Case>
          <Case condition={variation === 'photoForService'}>{i18n.t('cart_service_photos')}</Case>
          <Case condition={variation === 'photoForSector'}>{i18n.t('cart_sector_photos')}</Case>
          <Case condition={variation === 'photo'}>{i18n.t('cart_one_photos')}</Case>
        </Switch>
      </h3>

      <div>
        {items.map((item) => {
          return <Item key={item.commerceinfo.commerceid} variation={variation} isHistory={isHistory} {...item} />
        })}
      </div>
    </div>
  )
}

export default Items
