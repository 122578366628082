import React from 'react'
import s from './style.module.scss'

const PageEnd = ({ version, hash }) => {
  return (
    <div className={s.PageEnd}>
      <span>version: {version}</span>
      <span>hash: {hash}</span>
    </div>
  )
}

export default PageEnd
