/* global i18n */

import React from 'react'
import cn from 'classnames'
import { invertObj } from 'ramda'
import { Case, Switch } from 'react-if'
import { PhotoStatusCodes } from '../../../../common/constants.js'
import s from './style.module.scss'

const StatusIndicator = ({ status, approved }) => {
  return (
    <div className={cn('text-center', s.StatusIndicator, s[invertObj(PhotoStatusCodes)[status]])}>
      <Switch>
        <Case condition={status === PhotoStatusCodes.banned}>{i18n.t('global_banned')}</Case>
        <Case condition={status === PhotoStatusCodes.approved}>{/* {i18n.t('global_accepted')} */}</Case>
        <Case condition={status === PhotoStatusCodes.deletedByAdmin || status === PhotoStatusCodes.deletedByUser}>
          {i18n.t('status_deleted')}
        </Case>
        <Case condition={status === PhotoStatusCodes.pending}>
          {approved ? i18n.t('global_pending') : i18n.t('global_pending_company')}
        </Case>
        <Case condition={status === PhotoStatusCodes.featured}>
          {/* {i18n.t('global_featured')} */}
          {/* &nbsp; */}
          {/* <Tooltip label={i18n.t('myphotos_what_is_featured')}> */}
          {/*  <i className="fas fa-info-circle" /> */}
          {/* </Tooltip> */}
        </Case>
      </Switch>
    </div>
  )
}

export default StatusIndicator
