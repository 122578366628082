/* global i18n */

import React, { useEffect } from 'react'
import { compose } from 'ramda'
import { hot, setConfig } from 'react-hot-loader'
import { useSelector, useDispatch } from 'react-redux'
import LoadingText from '../../components/LoadingText/LoadingText.jsx'
import { xhrDelete, xhrGet, xhrPatch } from '../../helpers/http.js'
import { baseUrl } from '../../common/constants.js'
import Pagination from '../../components/Pagination/Pagination.jsx'
import Photo from './components/Photo/Photo.jsx'

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const MyPhotos = () => {
  const apiGetPhotos = async (limit, offset) => {
    return xhrGet(`${baseUrl}/web/profile/my-photos?offset=${offset}&limit=${limit}`)
  }

  const apiDeletePhoto = async (photoId) => {
    return xhrDelete(`${baseUrl}/web/photo/${photoId}`)
  }

  const apiDisablePhoto = async (photoId) => {
    return xhrPatch(`${baseUrl}/web/photo/${photoId}/disable`)
  }

  const apiEnablePhoto = async (photoId) => {
    return xhrPatch(`${baseUrl}/web/photo/${photoId}/enable`)
  }

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.MyPhotos.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'MyPhotos.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const limit = useSelector((state) => state.MyPhotos.limit)

  const offset = useSelector((state) => state.MyPhotos.offset)
  const setOffset = (offset) => {
    dispatch({
      type: 'MyPhotos.setOffset',
      payload: {
        offset
      }
    })
  }

  const photos = useSelector((state) => state.MyPhotos.photos)
  const setPhotos = (photos) => {
    dispatch({
      type: 'MyPhotos.setPhotos',
      payload: {
        photos
      }
    })
  }

  const totalNumberOfPhotos = useSelector((state) => state.MyPhotos.totalNumberOfPhotos)
  const setTotalNumberOfPhotos = (count) => {
    dispatch({
      type: 'MyPhotos.setTotalNumberOfPhotos',
      payload: {
        count
      }
    })
  }

  const loadPhotos = async () => {
    setIsLoading(true)
    try {
      const { photos, count } = await apiGetPhotos(limit, offset)
      setPhotos(photos)
      setTotalNumberOfPhotos(count)
    } catch (e) {
      console.error(`MyPhotos: loadPhotos failed`, await e.json())
    }
    setIsLoading(false)
  }

  const enablePhoto = async (photoId) => {
    setIsLoading(true)
    try {
      await apiEnablePhoto(photoId)
      dispatch({
        type: 'MyPhotos.enablePhoto',
        payload: {
          photoId
        }
      })
    } catch (e) {
      console.error(`MyPhotos: enablePhoto failed`, await e.json())
    }
    setIsLoading(false)
  }

  const disablePhoto = async (photoId) => {
    setIsLoading(true)
    try {
      await apiDisablePhoto(photoId)
      dispatch({
        type: 'MyPhotos.disablePhoto',
        payload: {
          photoId
        }
      })
    } catch (e) {
      console.error(`MyPhotos: disablePhoto failed`, await e.json())
    }
    setIsLoading(false)
  }

  const deletePhoto = async (photoId) => {
    if (confirm(i18n.t('delete_photo_confirm'))) {
      setIsLoading(true)
      try {
        await apiDeletePhoto(photoId)
        dispatch({
          type: 'MyPhotos.deletePhoto',
          payload: {
            photoId
          }
        })
      } catch (e) {
        console.error(`MyPhotos: deletePhoto failed`, await e.json())
      }
      setIsLoading(false)
    }
  }

  const onPaginationChange = (offset) => {
    if (isLoading === true) {
      return
    }
    setOffset(offset)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    loadPhotos()
  }, [offset])

  return (
    <>
      <LoadingText visible={isLoading} />
      <Pagination
        onChange={onPaginationChange}
        limit={limit}
        offset={offset}
        count={totalNumberOfPhotos}
        variant="top"
      />
      <div className="row">
        {photos.map((photo) => {
          return (
            <div key={photo.photoid} className="col-md-2 col-sm-6 col-xs-6">
              <Photo {...photo} onDisable={disablePhoto} onEnable={enablePhoto} onDelete={deletePhoto} />
            </div>
          )
        })}
      </div>
      <Pagination onChange={onPaginationChange} limit={limit} offset={offset} count={totalNumberOfPhotos} />
    </>
  )
}

export default enhance(MyPhotos)
