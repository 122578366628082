/* global i18n */

import React from 'react'
import { baseUrl } from '../../../../common/constants.js'
import Button from '../../../../components/Button/Button.jsx'

const Photo = ({ photoid, photodata, onContractListClick, created_at }) => {
  return (
    <div className="col-md-2 col-sm-6 col-xs-6 text-center" style={{ marginBottom: 30 }}>
      <div>{created_at}</div>
      <div>ID {photoid}</div>
      <a href={baseUrl + '/voucher/' + photoid}>
        <div
          className="mainimage"
          style={{
            marginTop: 5,
            backgroundImage: `url(${baseUrl}/photos/${photodata.filename})`,
            height: 180
          }}
        />
      </a>
      <div>
        <br />
        <Button color="primary" onClick={onContractListClick}>
          {i18n.t('btn_history_sold_view_licenses')}
        </Button>
      </div>
    </div>
  )
}

export default Photo
