export const isTronLinkReady = (walletAddress) => {
  return walletAddress && window?.tronWeb?.defaultAddress?.base58 === walletAddress
}

export const callConstantContract = (smartfunction, contractAddress, parameter = []) => {
  return new Promise((resolve, reject) => {
    ;(async () => {
      try {
        const options = {
          feeLimit: 1000000000,
          callValue: 0,
          shouldPollResponse: false
        }

        const result = await window.tronWeb.transactionBuilder.triggerConstantContract(
          contractAddress,
          smartfunction,
          options,
          parameter,
          window.tronWeb.defaultAddress.base58
        )
        resolve(result)
      } catch (err) {
        reject(err)
      }
    })()
  })
}

export const callTronlink = (smartfunction, value = 0, contractAddress, walletAddress, parameter = []) => {
  return new Promise((resolve, reject) => {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      if (window.tronWeb.defaultAddress.base58 !== walletAddress) {
        reject(new Error('Select your correct wallet on the TronLink extension'))
      } else {
        ;(async () => {
          try {
            const options = {
              feeLimit: 1000000000,
              callValue: value,
              shouldPollResponse: true
            }
            const tronweb = window.tronWeb
            const mybalance = await tronweb.trx.getBalance(window.tronWeb.defaultAddress.base58)

            if (mybalance < value) {
              reject(new Error("You don't have enough balance"))
            } else {
              const transaction = await tronweb.transactionBuilder.triggerSmartContract(
                contractAddress,
                smartfunction,
                options,
                parameter,
                window.tronWeb.defaultAddress.base58
              )
              const signedTx = await tronweb.trx.sign(transaction.transaction)
              const broastTx = await tronweb.trx.sendRawTransaction(signedTx)

              if (broastTx.result) {
                resolve({
                  smartfunction: smartfunction,
                  value: value,
                  parameter: parameter,
                  tx: broastTx.txid
                })
              } else {
                reject(new Error({ smartfunction: smartfunction }))
              }
            }
          } catch (e) {
            reject(e)
          }
        })()
      }
    } else {
      reject(new Error('Please login to TronLink extension wallet first'))
    }
  })
}
