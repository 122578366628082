/* global i18n, sendmessage */

import React, { useEffect } from 'react'
import { hot, setConfig } from 'react-hot-loader'
import { compose } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
// import LoadingText from '../../components/LoadingText/LoadingText.jsx'
import { When } from 'react-if'
import $ from 'jquery'
import { useEffectOnce } from '../../helpers/react.js'
import { xhrGet } from '../../helpers/http.js'
import { baseUrl } from '../../common/constants.js'
import Title from '../../components/Title/Title.jsx'
import EllipsisButton from '../../components/EllipsisButton/EllipsisButton.jsx'
// import Modal from '../../components/Modal/Modal.jsx'
import SocialShare from '../../components/SocialShare/SocialShare.jsx'
import s from './style.module.scss'

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const ViewPhoto = () => {
  const isLoggedIn = window.__initial_data__.user.customerId !== null
  const customerId = window.__initial_data__.user.customerId

  const apiGetPhotoData = async (photoId) => {
    return xhrGet(`${baseUrl}/web/photo/${photoId}`)
  }

  const getPhotoIdFromUrl = () => {
    // feltételezem, hogy az URL az alábbi formátumban van: /photo/(:num)
    return parseInt(location.pathname.replace(/^\//, '').split('/')[1])
  }

  const dispatch = useDispatch()

  // const isLoading = useSelector((state) => state.ViewPhoto.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'ViewPhoto.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const photoId = useSelector((state) => state.ViewPhoto.photoId)
  const setPhotoId = (photoId) => {
    dispatch({
      type: 'ViewPhoto.setPhotoId',
      payload: {
        photoId
      }
    })
  }

  const author = useSelector((state) => state.ViewPhoto.photoData.user || {})
  const photoTitle = useSelector((state) => state.ViewPhoto.photoData.name?.trim() || '')
  const photoData = useSelector((state) => state.ViewPhoto.photoData)
  const setPhotoData = (photoData) => {
    dispatch({
      type: 'ViewPhoto.setPhotoData',
      payload: {
        photoData
      }
    })
  }

  // console.log(photoData);

  const loadPhotoData = async () => {
    setIsLoading(true)
    const photoId = getPhotoIdFromUrl()
    setPhotoId(photoId)
    try {
      const photoData = await apiGetPhotoData(photoId)
      setPhotoData(photoData)
    } catch (e) {
      console.error('ViewPhoto: loadPhotoData failed', await e.json())
    }
    setIsLoading(false)
  }

  useEffectOnce(() => {
    loadPhotoData()
  })

  const onPhotoClick = () => {
    console.log('photoBuyModal')
    if ($('#photoBuyModal').length) {
      $('#photoBuyModal').modal('show')
    }
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v6.0'
    script.async = true
    script.crossOrigin = 'anonymous'
    document.body.appendChild(script)
  }, [])

  return (
    <>
      <div className={s.photoTitle}>
        <Title align="left" style={{ display: 'inline-block' }}>
          {photoTitle}
        </Title>
        <When condition={isLoggedIn}>
          {() => {
            const items = []

            if (customerId !== author.userid) {
              items.push({
                label: i18n.t('ellipsis_report_image'),
                onClick: () => {
                  sendmessage('admin', `report photo #${photoId}`)
                }
              })
            }

            return (
              <div className="more-btn">
                <EllipsisButton items={items} />
              </div>
            )
          }}
        </When>
      </div>
      <img src={`${photoData.small_image}`} onClick={onPhotoClick} />
      <div style={{ marginTop: 14 }} className="grey">
        {i18n.t('voucher_contact_information_available_when')}
      </div>
      <div className="photo-share">
        <h2 style={{ fontSize: '20px', fontWeight: '600' }}>{i18n.t('voucher_place_other')}</h2>
        <When condition={photoData.description !== null && photoData.description !== ' '}>
          <div className="mt-2 mb-2 pb-2">
            <article>
              <div>{photoData.description}</div>
            </article>
          </div>
        </When>
        <div>
          <div>
            <strong>{i18n.t('photo_share')}</strong>
          </div>
          <SocialShare
            title={photoData.name}
            description={photoData.description}
            url={`${baseUrl}/voucher/${photoData.photoid}`}
            image={photoData.small_image}
          />
        </div>
      </div>
      {/* <LoadingText visible={isLoading} /> */}
    </>
  )
}

export default enhance(ViewPhoto)
