import React from 'react'
import { When } from 'react-if'
import cn from 'classnames'
import { baseUrl, PhotoStatusCodes } from '../../../../common/constants.js'
import s from './style.module.scss'

const Preview = ({ photoId, status, disabledByUser, fileName, banText }) => {
  const isActive = !disabledByUser && (status === PhotoStatusCodes.approved || status === PhotoStatusCodes.featured)
  const linkToImage = `${baseUrl}/voucher/${photoId}`
  const imageSrc = `${baseUrl}/photos/${fileName}`

  const preventClickIfNotActive = (e) => {
    if (!isActive) {
      e.preventDefault()
    }
  }

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href={linkToImage}
        onClick={preventClickIfNotActive}
        className={cn('mainimage', s.Preview)}
        style={{
          cursor: isActive ? 'pointer' : 'default',
          marginTop: 0,
          opacity: isActive ? 1 : 0.5,
          backgroundImage: `url(${imageSrc})`,
          height: 306
        }}
      >
        <When condition={banText}>
          <span className={s.outer}>
            <span className={s.inner}>{banText}</span>
          </span>
        </When>
      </a>
    </>
  )
}

export default Preview
