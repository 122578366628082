/* global i18n */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { pathEq, filter, compose, flatten, values } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import Button from '../../../../components/Button/Button.jsx'
import { xhrPost } from '../../../../helpers/http.js'
import { baseUrl } from '../../../../common/constants.js'

const AbortPayment = () => {
  const flatItemList = useSelector((state) => flatten(values(state.Cart.items)))

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.Cart.isLoading)

  const isUnderPayment = useSelector((state) => state.Cart.isUnderPayment)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Cart.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const setItems = (items) => {
    dispatch({
      type: 'Cart.setItems',
      payload: {
        items
      }
    })
  }

  const apiAbortPayment = async () => {
    return xhrPost(`${baseUrl}/web/cart/payment/abort`)
  }

  const abortPayment = async () => {
    setIsLoading(true)
    setItems(await apiAbortPayment())
    setIsLoading(false)
  }

  const hasAbortableItems = compose(
    isNotEmpty,
    filter(pathEq(['commerceinfo', 'statusText'], 'ready to pay'))
  )(flatItemList)

  return (
    <Button disabled={isLoading || !hasAbortableItems || isUnderPayment} onClick={abortPayment} color="red">
      {i18n.t('cart_abort_payment')}
    </Button>
  )
}

export default AbortPayment
