/* global i18n */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { flatten, values, any, path, equals, compose, either } from 'ramda'
import Button from '../../../../components/Button/Button.jsx'
import { xhrPost } from '../../../../helpers/http.js'
import { baseUrl } from '../../../../common/constants.js'

const PayWithOrigoKey = () => {
  const apiStartPayment = async () => {
    return xhrPost(`${baseUrl}/web/cart/payment/start`, {
      method: 'origokey'
    })
  }

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.Cart.isLoading)

  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'Cart.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const setItems = (items) => {
    dispatch({
      type: 'Cart.setItems',
      payload: {
        items
      }
    })
  }

  const startPayment = async () => {
    setIsLoading(true)

    try {
      setItems(await apiStartPayment())
    } catch (e) {
      console.error('PayWithWallet: startPayment failed', await e.json())
    }

    setIsLoading(false)
  }

  const flatItemList = useSelector((state) => flatten(values(state.Cart.items)))

  const hasPayableItems = any(
    compose(either(equals('in cart'), equals('payment failed')), path(['commerceinfo', 'statusText']))
  )(flatItemList)

  return (
    <Button nowrap disabled={isLoading || !hasPayableItems} onClick={startPayment} color="red">
      {i18n.t('cart_pay_with_origokey_wallet')}
    </Button>
  )
}

export default PayWithOrigoKey
