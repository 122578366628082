import React from 'react'
import { trim, compose, replace, identity } from 'ramda'

const DateTime = ({ input, inputFormat }) => {
  let parser
  switch (inputFormat) {
    case 'atom':
      // atom formátum = Y-m-d\TH:i:sP
      parser = compose(replace('Z', ''), replace('T', ' '), trim)
      break
    default:
      parser = identity
  }

  const parsedTime = parser(input)

  return <div>{parsedTime}</div>
}

export default DateTime
