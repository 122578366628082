/* global i18n */

import React from 'react'
import cn from 'classnames'
import s from './style.module.scss'

const LoadingText = ({ visible = true }) => {
  return (
    <div className={cn('LoadingText', s.LoadingText, { [s.visible]: visible })}>
      {i18n.t('loading')}&hellip;
    </div>
  )
}

export default LoadingText
