import React from 'react'
import { compose } from 'ramda'
import { hot, setConfig } from 'react-hot-loader'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import { When, Unless } from 'react-if'
import LoadingText from '../../components/LoadingText/LoadingText.jsx'
import Title from '../../components/Title/Title.jsx'
import { useEffectOnce } from '../../helpers/react.js'
import { xhrDelete, xhrGet, xhrPatch, xhrPost } from '../../helpers/http.js'
import Button from '../../components/Button/Button.jsx'
import { baseUrl } from '../../common/constants.js'
import s from './style.module.scss'

setConfig({
  reloadHooks: false
})

const enhance = compose(hot(module))

const DevReferrals = () => {
  const apiGetReferrals = async () => {
    return xhrGet(`${baseUrl}/dev/api/referrals`)
  }

  const apiPostRegenerateReferralTable = async () => {
    return xhrPost(`${baseUrl}/dev/api/referrals/rebuild`)
  }

  const apiPostResetReferral = async (userId) => {
    return xhrPost(`${baseUrl}/dev/api/referrals/reset/${userId}`)
  }

  const apiGetGenerateUserId = async () => {
    return xhrGet(`${baseUrl}/dev/api/referrals/generate-user-id`)
  }

  const apiPatchChangeUserId = async (userId, newUserId) => {
    return xhrPatch(`${baseUrl}/dev/api/user/${userId}`, {
      newUserId
    })
  }

  const apiDeleteRemoveUser = async (userId) => {
    return xhrDelete(`${baseUrl}/dev/api/user/${userId}`)
  }

  const dispatch = useDispatch()

  const isLoading = useSelector((state) => state.DevReferrals.isLoading)
  const setIsLoading = (isLoading) => {
    dispatch({
      type: 'DevReferrals.setIsLoading',
      payload: {
        isLoading
      }
    })
  }

  const users = useSelector((state) => state.DevReferrals.users)
  const setUsers = (users) => {
    dispatch({
      type: 'DevReferrals.setUsers',
      payload: {
        users
      }
    })
  }

  const defaultReferral = useSelector((state) => state.DevReferrals.defaultReferral)
  const setDefaultReferral = (defaultReferral) => {
    dispatch({
      type: 'DevReferrals.setDefaultReferral',
      payload: {
        defaultReferral
      }
    })
  }

  const sum = useSelector((state) => state.DevReferrals.sum)
  const setSum = (sum) => {
    dispatch({
      type: 'DevReferrals.setSum',
      payload: {
        sum
      }
    })
  }

  const fetchAllUsers = async () => {
    const { users, defaultReferral, sum } = await apiGetReferrals()
    setUsers(users)
    setDefaultReferral(defaultReferral)
    setSum(sum)
  }

  const loadUsers = async () => {
    setIsLoading(true)
    try {
      await fetchAllUsers()
    } catch (e) {
      console.error(`DevReferrals: loadUsers failed`, await e.json())
    }
    setIsLoading(false)
  }

  const regenerateReferralTable = async () => {
    setIsLoading(true)
    try {
      await apiPostRegenerateReferralTable()
      await fetchAllUsers()
    } catch (e) {
      console.error(`DevReferrals: regenerateReferralTable failed`, await e.json())
    }
    setIsLoading(false)
  }

  const resetReferral = async (userId) => {
    setIsLoading(true)
    try {
      await apiPostResetReferral(userId)
      await fetchAllUsers()
    } catch (e) {
      console.error(`DevReferrals: resetReferral failed`, await e.json())
    }
    setIsLoading(false)
  }

  const generateUserId = async () => {
    let id = null
    setIsLoading(true)
    try {
      id = await apiGetGenerateUserId()
    } catch (e) {
      console.log(`DevReferrals: generateUserId failed`, await e.json())
    }
    setIsLoading(false)
    if (id !== null) {
      setTimeout(() => {
        alert(id)
      }, 10)
    }
  }

  const isValidUserId = (id) => {
    return /^[1-9]\d{6}$/.test(id)
  }

  const changeUserId = async (userId) => {
    const generatedUserId = await apiGetGenerateUserId()
    const newUserId = prompt(
      `Please specify a new ID for user ${userId} (should be a 7 digit number):`,
      generatedUserId
    )
    if (newUserId !== false && isValidUserId(newUserId)) {
      let message = null
      setIsLoading(true)
      try {
        const body = await apiPatchChangeUserId(userId, newUserId)
        message = body.message
        await fetchAllUsers()
      } catch (e) {
        const body = await e.json()
        message = body.messages.error
        console.error(`DevReferrals: changeUserId failed`, message)
      }
      setIsLoading(false)
      setTimeout(() => {
        alert(message)
      }, 10)
    }
  }

  const removeUser = async (userId) => {
    if (
      !confirm('This will permanently delete the user and all his/her data. Do you really want to delete the user?')
    ) {
      return
    }

    setIsLoading(true)
    let message = null
    try {
      await apiDeleteRemoveUser(userId)
      message = 'user has been deleted'
      await fetchAllUsers()
    } catch (e) {
      const body = await e.json()
      message = body.messages.error
      console.error(`DevReferrals: deleteUser failed`, message)
    }
    setIsLoading(false)
    setTimeout(() => {
      alert(message)
    }, 10)
  }

  useEffectOnce(() => {
    loadUsers()
  })

  return (
    <>
      <Title>Referrals</Title>
      <LoadingText visible={isLoading} />
      <Button className={s.titleButton} onClick={regenerateReferralTable}>
        regenerate referral table
      </Button>
      <Button className={s.titleButton} onClick={generateUserId}>
        generate unique id
      </Button>
      <p>
        Default referral: <b>{defaultReferral}</b>
      </p>
      <table className={s.userList}>
        <thead>
          <tr>
            <th rowSpan="2">User Id</th>
            <th rowSpan="2">Referral Id</th>
            <th rowSpan="2">Username</th>
            <th colSpan="2">Company User</th>
            <th colSpan="4">Errors</th>
            <th rowSpan="2" />
          </tr>
          <tr>
            <th className={s.companySevenUser}>7</th>
            <th className={s.companyThreeUser}>3</th>
            <th className={cn(s.errorColumn, sum.isReferringIntermediateCompanyUser > 0 ? s.error : '')}>
              Referring Intermediate Company User
              <When condition={sum.isReferringIntermediateCompanyUser > 0}>
                <br />({sum.isReferringIntermediateCompanyUser})
              </When>
            </th>
            <th className={cn(s.errorColumn, sum.isSelfReferencing > 0 ? s.error : '')}>
              Self Referencing
              <When condition={sum.isSelfReferencing > 0}>
                <br />({sum.isSelfReferencing})
              </When>
            </th>
            <th className={cn(s.errorColumn, sum.isReferringInvalidOrZero > 0 ? s.error : '')}>
              Referring invalid user or #0
              <When condition={sum.isReferringInvalidOrZero > 0}>
                <br />({sum.isReferringInvalidOrZero})
              </When>
            </th>
            <th className={cn(s.errorColumn, sum.hasNonUniqueReferralTree > 0 ? s.warning : '')}>
              Non-unique referral tree
              <When condition={sum.hasNonUniqueReferralTree > 0}>
                <br />({sum.hasNonUniqueReferralTree})
              </When>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr
                key={user.userId}
                className={cn({
                  [s.error]:
                    user.isSelfReferencing || user.isReferringIntermediateCompanyUser || user.isReferringInvalidOrZero,
                  [s.warning]: user.hasNonUniqueReferralTree
                })}
              >
                <td
                  className={cn(
                    user.isCompanySevenUser ? s.companySevenUser : '',
                    user.isCompanyThreeUser ? s.companyThreeUser : ''
                  )}
                >
                  <Button
                    onClick={() => changeUserId(user.userId)}
                    className={s.Button}
                    icon
                    color="red"
                    title="Change user ID"
                  >
                    <i className="fas fa-pen" />
                  </Button>
                  {user.userId}
                </td>
                <td
                  className={cn(
                    user.isReferralCompanySevenUser ? s.companySevenUser : '',
                    user.isReferralCompanyThreeUser ? s.companyThreeUser : ''
                  )}
                >
                  {user.referralId}
                </td>
                <td
                  className={cn(
                    user.isCompanySevenUser ? s.companySevenUser : '',
                    user.isCompanyThreeUser ? s.companyThreeUser : ''
                  )}
                >
                  {user.username}
                </td>
                <td className={cn(user.isCompanySevenUser ? s.companySevenUser : '')}>
                  {user.isCompanySevenUser ? '×' : ''}
                </td>
                <td className={cn(user.isCompanyThreeUser ? s.companyThreeUser : '')}>
                  {user.isCompanyThreeUser ? '×' : ''}
                </td>
                <td>{user.isReferringIntermediateCompanyUser ? '×' : ''}</td>
                <td>{user.isSelfReferencing ? '×' : ''}</td>
                <td>{user.isReferringInvalidOrZero ? '×' : ''}</td>
                <td>{user.hasNonUniqueReferralTree ? '×' : ''}</td>
                <td className={s.actions}>
                  <Unless condition={user.hasDefaultReferral || user.isCompanySevenUser}>
                    <Button onClick={() => resetReferral(user.userId)}>reset referral</Button>
                  </Unless>
                  <Unless condition={user.isCompanySevenUser || user.isCompanyThreeUser}>
                    <Button icon color="red" onClick={() => removeUser(user.userId)} title="Delete user">
                      <i className="fas fa-trash" />
                    </Button>
                  </Unless>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <br />
      <br />
      <p>
        <strong>Referring Intermediate Company User:&nbsp;</strong>
        The user is referencing a "company&nbsp;seven" user, which is not Kirill (7435514), the lowest in the structure
        of company users. This is only acceptable for "company&nbsp;seven" users.
        <br />
        <i>Fix: Reset referral will fix this</i>
      </p>
      <p>
        <strong>Self Referencing:&nbsp;</strong>
        The user has him/herself as referral.
        <br />
        <i>Fix: Reset referral will fix this</i>
      </p>
      <p>
        <strong>Referring invalid user or #0:&nbsp;</strong>
        The user is referring to a non-existent user. The user can also refer to 0, which is a virtual user on top of
        the hierarchy and can only be referred by the top "company&nbsp;seven" user or admins.
        <br />
        <i>Fix: Reset referral will fix this</i>
      </p>
      <p>
        <strong>Non-unique referral tree:&nbsp;</strong>
        When going up from referral to referral starting from the user's own referral there are users, which we come
        across more than once before reaching 0.
        <br />
        <i>
          Fix: Users and their referrals should be checked manually, there should be 2 users, who refer to each other
          causing loops among referrals. One of those invalid users should have their referrals reset.
        </i>
      </p>
      <br />
      <p>
        After doing any edits for the users either on this page or in phpmyadmin directly, then the
        <b>regenerate&nbsp;referral&nbsp;table button should be pressed</b>
      </p>
    </>
  )
}

export default enhance(DevReferrals)
