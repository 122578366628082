/* global i18n */

import React, { useEffect, useState } from 'react'
import moment from 'moment'
import s from './style.module.scss'

const ServiceSelector = ({ services, subsequent, isCompanyUser }) => {
  let servicesDate = '...'
  const [activeServices, setActiveServices] = useState([])

  useEffect(() => {
    setActiveServices(services.filter((serviceItem) => serviceItem.active === 1))
  }, [services])

  if (subsequent) {
    const currentLanguage = document.getElementById('current-language').innerText.trim().toLowerCase()
    import(`moment/locale/${currentLanguage === 'en' ? currentLanguage + '-gb' : currentLanguage}`)
    moment.locale(currentLanguage)
    const from = moment(
      activeServices.length > 0 ? activeServices[activeServices.length - 1]?.to : new Date(),
      'YYYY.MM.DD',
      currentLanguage
    )

    const to = moment(from)

    if (activeServices.length > 0) {
      from.add(1, 'day')
      to.add(30, 'days')
    } else {
      to.add(29, 'days')
    }

    servicesDate = `${from.format('L')} - ${to.format('L')}`
  } else {
    if (isCompanyUser) {
      servicesDate = i18n
        .t('active')
        .split('<br>')
        .map((str, key) => <p key={key}>{str}</p>)
    } else if (activeServices && activeServices.length > 0 && activeServices[0]?.active === 1) {
      const currentLanguage = document.getElementById('current-language').innerText.trim().toLowerCase()
      import(`moment/locale/${currentLanguage === 'en' ? currentLanguage + '-gb' : currentLanguage}`)
      moment.locale(currentLanguage)
      const to = moment(activeServices.filter((el) => el.active).reverse()[0]?.to, 'YYYY.MM.DD', currentLanguage)

      servicesDate = `${i18n.t('date_to')}: ${to.format('L')}`
    } else {
      servicesDate = i18n
        .t('no_activation')
        .split('<br>')
        .map((str, key) => <p key={key}>{str}</p>)
    }
  }

  return (
    <div
      id="service-date"
      data-serviceid={services.length > 1 ? services.find((el) => !el.active)?.serviceid : services[0]?.serviceid}
      className={`${s.ServiceSelector} ${
        !isCompanyUser && !subsequent && (!activeServices || activeServices.length === 0) ? s.ServiceSelectorRed : ''
      }`}
    >
      {servicesDate}
    </div>
  )
}

export default ServiceSelector
