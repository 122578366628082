/* global i18n */
import React, { useEffect } from 'react'
import { If, Then, Else } from 'react-if'
import Button from '../../../../components/Button/Button.jsx'

const ToggleVisibility = ({ photoId, onEnable, onDisable, isVisible }) => {
  useEffect(() => {
    const $tooltipEls = window.$('[data-toggle="tooltip-eye"]')
    if ($tooltipEls.length > 0) {
      $tooltipEls.tooltip()
    }
  }, [])

  return (
    <div data-toggle="tooltip-eye" data-placement="right" data-trigger="hover" title={i18n.t('photo_checkbox_desc')}>
      <If condition={isVisible}>
        <Then>
          <Button icon onClick={() => onDisable(photoId)}>
            <i className="fas fa-eye" />
          </Button>
        </Then>
        <Else>
          <Button
            icon
            onClick={() => onEnable(photoId)}
            data-toggle="tooltip-eye"
            data-placement="right"
            data-trigger="hover"
            title={i18n.t('photo_checkbox_desc')}
          >
            <i className="fas fa-eye-slash" />
          </Button>
        </Else>
      </If>
    </div>
  )
}

export default ToggleVisibility
