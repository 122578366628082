/* global i18n, hidenotify, sendmessage, shownotify, formToJSON, isStorageAvailable, restoreData, persistData, notify */

import React, { useEffect, useState, useRef } from 'react'
import { If, Then, Else, When } from 'react-if'
import cn from 'classnames'
import $ from 'jquery'
import { defaultTo } from 'ramda'
import Button from '../../../../components/Button/Button.jsx'
import Tooltip from '../../../../components/Tooltip/Tooltip.jsx'
import EllipsisButton from '../../../../components/EllipsisButton/EllipsisButton.jsx'
import DateTime from '../../../../components/DateTime/DateTime.jsx'
import { xhrDelete, xhrPost } from '../../../../helpers/http.js'
import { baseUrl } from '../../../../common/constants.js'
import Comments from '../Comments/Comments.jsx'
import SocialShare from '../../../../components/SocialShare/SocialShare.jsx'
import s from './style.module.scss'

const BlogItem = ({
  user,
  event,
  stats,
  comments,
  photo,
  commentsEnabled,
  toggleBlogPostComments,
  translateBlogPosts
}) => {
  const isLoggedIn = window.__initial_data__.user.isAdmin || window.__initial_data__.user.customerId !== null
  const meta = window.__blog
  const amIBeingBlocked = window.__amIBeingBlocked
  const isBlockedByMe = window.__isBlockedByMe
  const { isGuest, PhotogramBlogUserID, customerId } = meta

  const [numberOfLikes, setNumberOfLikes] = useState(0)
  const [isAmongLikers, setIsAmongLikers] = useState(false)
  const [translated, setTranslated] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [numberOfComments, setNumberOfComments] = useState(0)
  const [imageOrientation, setImageOrientation] = useState('landscape')

  const NewCommentSubmitRef = useRef(null)
  const CommentsRef = useRef(null)

  useEffect(() => {
    setNumberOfLikes(stats.likes)
    setIsAmongLikers(stats.amongLikers)
    setNumberOfComments(stats.comments)
  }, [stats])

  const deleteBlogPost = async (blogID) => {
    try {
      await xhrDelete(`${baseUrl}/web/blog/my-posts/${blogID}`)
      if (isStorageAvailable('localStorage')) {
        const notifications = restoreData('notifications', [], defaultTo)
        persistData('notifications', [
          ...notifications,
          {
            title: i18n.t('popup_title_blog_delete'),
            subtitle: i18n.t('blog_delete_successful'),
            severity: 'success'
          }
        ])
      }
      document.location.reload(true)
    } catch (e) {
      notify(i18n.t('popup_title_blog_delete'), i18n.t('blog_delete_failed'), 'error')
    }
  }

  const onBlogPostDeleteClick = (blogPostId) => {
    if (confirm(i18n.t('blog_delete_confirm'))) {
      deleteBlogPost(blogPostId)
    }
  }

  const onBlogPostDisableClick = async (blogPostId) => {
    await toggleBlogPostComments(blogPostId, 0)
  }

  const onBlogPostEnableClick = async (blogPostId) => {
    await toggleBlogPostComments(blogPostId, 1)
  }

  const toggleLike = async () => {
    const apiURL = `${baseUrl}/web/blog/${isAmongLikers ? 'unlike' : 'like'}`

    const { likes, amongLikers } = await xhrPost(apiURL, {
      blogid: event.id
    })

    setNumberOfLikes(likes)
    setIsAmongLikers(amongLikers)
  }

  const showShare = () => {
    setShareModal(!shareModal)
  }

  const addComment = async (e) => {
    e.preventDefault()
    const submitBtn = NewCommentSubmitRef.current

    if (amIBeingBlocked) {
      shownotify(submitBtn, i18n.t('cant_comment_blocked'))
      return
    }

    if (isBlockedByMe) {
      shownotify(submitBtn, i18n.t('cant_comment_blocking'))
      return
    }

    const apiURL = `${baseUrl}/web/blog/comment`
    await xhrPost(apiURL, formToJSON(e.target))

    await CommentsRef.current.adjustNumberOfComments(1)
    setNumberOfComments(numberOfComments + 1)

    e.target.reset()
  }

  const blockUser = async (userId) => {
    await xhrPost(`${baseUrl}/web/user/block`, {
      userId
    })
    console.log('blokkolva; TODO: frissíteni a listát')
    document.location.reload(true)
  }

  const unblockUser = async (userId) => {
    await xhrPost(`${baseUrl}/web/user/unblock`, {
      userId
    })
    console.log('unblokkolva; TODO: frissíteni a listát')
    document.location.reload(true)
  }

  const onImageLoaded = (e) => {
    const img = e.target
    setImageOrientation(img.naturalWidth > img.naturalHeight ? 'landscape' : 'portrait')
  }

  const handleTranslationClick = async () => {
    await translateBlogPosts(event.id, !translated)
    setTranslated(!translated)
  }

  return (
    <div className={cn('blogitem', s.BlogItem)}>
      <div className="blog-item-header">
        <div className="avatar">
          <a href={`${baseUrl}/blog/${user.blogname}`}>
            <img src={user.avatarimage} className="avatarimage" width="50" height="50" />
          </a>
        </div>
        <div className="author">
          <If condition={parseInt(user.id) === PhotogramBlogUserID}>
            <Then>
              <strong>{user.blogname}</strong>
            </Then>
            <Else>
              <strong>{user.blogname}</strong>
              <small>ID {user.id}</small>
            </Else>
          </If>
        </div>
        <When condition={isLoggedIn}>
          {() => {
            const items = []

            if (customerId === parseInt(user.id)) {
              items.push({
                label: i18n.t('ellipsis_delete_post'),
                onClick: () => onBlogPostDeleteClick(event.id)
              })
              items.push({
                label: i18n.t(`ellipsis_${commentsEnabled ? 'disable' : 'enable'}_comments`),
                onClick: () => (commentsEnabled ? onBlogPostDisableClick : onBlogPostEnableClick)(event.id)
              })
            } else {
              items.push({
                label: i18n.t('ellipsis_send_message'),
                onClick: () => {
                  sendmessage(user.id)
                }
              })

              if (user.isBlockedByUs) {
                items.push({
                  label: i18n.t('ellipsis_unblock_user'),
                  onClick: () => {
                    unblockUser(user.id)
                  }
                })
              } else {
                items.push({
                  label: i18n.t('ellipsis_block_user'),
                  onClick: () => {
                    blockUser(user.id)
                  }
                })
              }

              items.push({
                label: i18n.t('ellipsis_report_image'),
                onClick: () => {
                  sendmessage('admin', `report blog post #${event.id}`)
                }
              })
            }

            return (
              <div className="more-btn">
                <EllipsisButton items={items} />
              </div>
            )
          }}
        </When>
        <div className="creation-date">
          <DateTime input={event.time} inputFormat="atom" />
        </div>
      </div>
      {!!photo.src && (
        <div className={cn(s.mainImage, s[imageOrientation])}>
          <img src={photo.src} alt={photo.description} onLoad={onImageLoaded} />
        </div>
      )}

      <div style={{ paddingTop: 16 }}>
        <small style={{ float: 'right', fontSize: '0.8em' }}>#{event.id}</small>
        <p style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{photo.description}</p>
      </div>
      <div className="d-flex justify-content-end">
        <a className="link" onClick={() => handleTranslationClick()}>
          {translated ? i18n.t('blog_show_original_post') : i18n.t('blog_translate_post')}
        </a>
      </div>

      <hr style={{ marginBottom: 5 }} />

      <div className="flex justify-content-between">
        <div className="bloginfo">
          <span>
            <i className="fas fa-thumbs-up" />
            &nbsp;&nbsp;
            {numberOfLikes}
            &nbsp;
            {i18n.t('global_likes')}
          </span>
          <If condition={commentsEnabled}>
            <Then>
              {() => (
                <span>
                  <i className="fas fa-comments" />
                  &nbsp;&nbsp;
                  {numberOfComments}
                  &nbsp;
                  {i18n.t('global_comments')}
                </span>
              )}
            </Then>
          </If>
        </div>
        <div>
          <Button onClick={() => showShare()} className={cn('like-btn btn_0001')} onMouseOut={hidenotify()}>
            <i className="fas fa-share speciali" />
            <span>Share</span>
          </Button>
          <If condition={isGuest}>
            <Then>{() => <></>}</Then>
            <Else>
              {() => (
                <Button
                  onClick={() => toggleLike()}
                  className={cn('like-btn btn_0001', isAmongLikers ? 'active' : '')}
                  onMouseOut={hidenotify()}
                >
                  <i className="fas fa-thumbs-up speciali" />
                  <span>Like</span>
                </Button>
              )}
            </Else>
          </If>
        </div>
      </div>
      {shareModal && (
        <div className="blog-post__share" role="dialog">
          <SocialShare url={`${baseUrl}/blog/find-post/${event.id}`} />
        </div>
      )}

      <hr style={{ marginTop: 5, marginBottom: 20 }} />

      <If condition={isGuest}>
        <Then>
          {() => (
            <If condition={commentsEnabled}>
              <Then>
                {() => (
                  <div style={{ display: 'flex' }}>
                    <input
                      type="text"
                      className="form-control"
                      name="comment"
                      placeholder={i18n.t('blog_your_comment')}
                    />
                    <Tooltip label={i18n.t('blog_need_login_for_comment')}>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm modal-button btn-red text-capitalize"
                        onClick={() => {
                          document.querySelector('.open-login').click()
                          $(document).scrollTop(0)
                        }}
                        style={{ width: 120, marginLeft: 20 }}
                      >
                        {i18n.t('blog_send')}
                      </button>
                    </Tooltip>
                  </div>
                )}
              </Then>
            </If>
          )}
        </Then>
        <Else>
          {() => (
            <If condition={commentsEnabled}>
              <Then>
                {() => (
                  <form method="post" encType="multipart/form-data" style={{ display: 'flex' }} onSubmit={addComment}>
                    <input type="hidden" name="photoid" value={photo.id} />
                    <input type="hidden" name="event-id" value={event.id} />
                    <input type="hidden" name="event-type" value={event.type} />
                    <input
                      type="text"
                      className="form-control"
                      name="comment"
                      placeholder={i18n.t('blog_your_comment')}
                    />
                    <button
                      ref={NewCommentSubmitRef}
                      type="submit"
                      className="btn btn-secondary btn-sm modal-button btn-red text-capitalize"
                      style={{ width: 120, marginLeft: 20 }}
                      onMouseOut={hidenotify}
                    >
                      {i18n.t('blog_send')}
                    </button>
                  </form>
                )}
              </Then>
            </If>
          )}
        </Else>
      </If>

      <If condition={commentsEnabled}>
        <Then>
          {() => (
            <Comments
              ref={CommentsRef}
              blogPostId={event.id}
              comments={comments}
              allComments={numberOfComments}
              onNumberOfCommentsChange={(amount) => {
                setNumberOfComments(numberOfComments + amount)
              }}
            />
          )}
        </Then>
      </If>
    </div>
  )
}

export default BlogItem
